import React, { useState, useEffect, useRef } from "react";

import * as Yup from "yup";
import {
  Formik,
  Form,
  Field
} from "formik";

import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  makeStyles,
  TextField,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { green } from "@material-ui/core/colors";
import { i18n } from "../../translate/i18n";
import QueueOneSelect from "../QueueOneSelect";

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import ConditionList from "../ConditionList";
import api from "../../services/api";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginRight: theme.spacing(1),
    flex: 1,
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  btnWrapper: {
    position: "relative",
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },

  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },

  colorAdorment: {
    width: 20,
    height: 20,
  },
  dates: {
    margin: 20,
    width: 50
  },
  select: {
    marginRight: theme.spacing(1),
    width: 100
  }
}));
const generateRandomHash = (length) => {
  const charset = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const values = new Uint8Array(length);
  window.crypto.getRandomValues(values);
  let randomString = '';
  for (let i = 0; i < length; i++) {
    randomString += charset[values[i] % charset.length];
  }
  return randomString;
}

const FluxoModal = ({ open, onClose, onResult, conditionId, queueId }) => {
  const classes = useStyles();
  const handleClose = () => {
    onClose();
  };
  const [description, setDescription] = useState('');
  const [conditions, setConditions] = useState([{ conditionId: 'default' + generateRandomHash(16), type: "Transferir", isDefault: true, queueId }]);

  const handleSaveCondition = async () => {
    const form = {
      description,
      rules: conditions,
      queueId
    }
    try {
      onResult(form)
      handleClose();
    }
    catch (error) {
      throw error;
    }
  };
  const handleResult = (result) => {
    console.log('Fluxo Result', result)
  }
  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };
  // handleSaveCondition()
  return (
    <div className={classes.root}>
      <Dialog open={open} onClose={handleClose} scroll="paper">
        <DialogTitle>
          {conditionId
            ? 'Editar Fluxo'
            : 'Adicionar Fluxo'}


        </DialogTitle>
        <Formik
          enableReinitialize={true}
        >
          {({ touched, errors, isSubmitting, values }) => (

            <Form>
              <DialogContent dividers>
                <FormControl variant="outlined" margin="dense" className={classes.textField} style={{ width: '100%' }}>
                  <Field
                    as={TextField}
                    label="Descrição"
                    autoFocus
                    variant="outlined"
                    margin="dense"
                    value={description}
                    onChange={handleDescriptionChange}
                    style={{ width: '98%' }}
                    className={classes.textField}
                  />
                </FormControl>
                <ConditionList data={conditions || []} onResult={handleResult} queueId={queueId}/>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleClose}
                  color="secondary"
                  disabled={isSubmitting}
                  variant="outlined"
                >
                  {i18n.t("queueModal.buttons.cancel")}
                </Button>
                <Button
                  onClick={handleSaveCondition}
                  color="primary"
                  disabled={isSubmitting}
                  variant="contained"
                  className={classes.btnWrapper}
                >
                  {conditionId
                    ? `${i18n.t("queueModal.buttons.okEdit")}`
                    : `${i18n.t("queueModal.buttons.okAdd")}`}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </div>
  );
};

export default FluxoModal;
