import React, { useState, useEffect, useRef } from "react";

import * as Yup from "yup";
import {
	Formik,
	Form,
	Field
} from "formik";

import {
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	IconButton,
	InputAdornment,
	makeStyles,
	TextField,
	InputLabel,
	FormControl,
	Select,
	MenuItem,
} from "@material-ui/core";

import { green } from "@material-ui/core/colors";
import { toast } from "react-toastify";
import { i18n } from "../../translate/i18n";

import api from "../../services/api";
import toastError from "../../errors/toastError";
import ColorPicker from "../ColorPicker";
import { Colorize } from "@material-ui/icons";

import QueueOneSelect from "../QueueOneSelect";

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import SubQueueList from "../SubQueueList";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import ConditionList from "../ConditionList";
import QueueUploadModal from "../QueueUploadModal";
const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		flexWrap: "wrap",
	},
	textField: {
		marginRight: theme.spacing(1),
		flex: 1,
	},
	container: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	btnWrapper: {
		position: "relative",
	},
	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},

	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},

	colorAdorment: {
		width: 20,
		height: 20,
	},
	dates: {
		margin: 20,
		width: 50
	},
	select: {
		marginRight: theme.spacing(1),
		width: 100
	}
}));

const QueueSchema = Yup.object().shape({
	name: Yup.string()
		.min(2, "Too Short!")
		.max(50, "Too Long!")
		.required("Required"),
	color: Yup.string().min(3, "Too Short!").max(9, "Too Long!").required(),
	position: Yup.string(),
	greetingMessage: Yup.string(),
	startWork: Yup.string(),
	endWork: Yup.string(),
	absenceMessage: Yup.string()
});
const QueueModal = ({ open, onClose, queueId, nextId, ParentId }) => {
	const classes = useStyles();

	const initialState = {
		name: "",
		color: "",
		greetingMessage: "",
		startWork: "",
		endWork: "",
		absenceMessage: "",
		startWorkSunMorning: "",
		parentId: ParentId,
	};


	const [colorPickerModalOpen, setColorPickerModalOpen] = useState(false);
	const [queue, setQueue] = useState(initialState);
	const [parentId, setParentId] = useState(ParentId);
	const [transferId, setTransferId] = useState(null);
	const [blockClose, setBlockClose] = useState(false);
	const [openQueueUploadModal, setOpenQueueUploadModal] = useState(false)

	const greetingRef = useRef();
	const absenceRef = useRef();
	const startWorkRef = useRef();
	const endWorkRef = useRef();

	//dates

	const startWorkSunMorningRef = useRef();
	const endWorkSunMorningRef = useRef();
	const startWorkSunAfternoonRef = useRef();
	const endWorkSunAfternoonRef = useRef();

	const startWorkMonMorningRef = useRef();
	const endWorkMonMorningRef = useRef();
	const startWorkMonAfternoonRef = useRef();
	const endWorkMonAfternoonRef = useRef();

	const startWorkTueMorningRef = useRef();
	const endWorkTueMorningRef = useRef();
	const startWorkTueAfternoonRef = useRef();
	const endWorkTueAfternoonRef = useRef();

	const startWorkWedMorningRef = useRef();
	const endWorkWedMorningRef = useRef();
	const startWorkWedAfternoonRef = useRef();
	const endWorkWedAfternoonRef = useRef();

	const startWorkThuMorningRef = useRef();
	const endWorkThuMorningRef = useRef();
	const startWorkThuAfternoonRef = useRef();
	const endWorkThuAfternoonRef = useRef();

	const startWorkFriMorningRef = useRef();
	const endWorkFriMorningRef = useRef();
	const startWorkFriAfternoonRef = useRef();
	const endWorkFriAfternoonRef = useRef();

	const startWorkSatMorningRef = useRef();
	const endWorkSatMorningRef = useRef();
	const startWorkSatAfternoonRef = useRef();
	const endWorkSatAfternoonRef = useRef();

	const [showDays, setShowDays] = useState(false);
	const [showMessages, setShowMessages] = useState(false);
	const [showSubniveis, setShowSubniveis] = useState(false);
	const [showQueue, setShowQueue] = useState(true);
	const [onDragEnter, setOnDragEnter] = useState(false);
	const [media, setMedia] = useState(null);
	const [initialLevel, setInitialLevel] = useState(null);
	const [fluxoId, setFluxoId] = useState(null);
	const [conditions, setConditions] = useState([]);
	const getCondition = async () => {
		const response = await api.get('/fluxo', { params: { queueId } });
		setFluxoId(response?.data?.fluxos[0]?.id || null)
		setConditions(response?.data?.fluxos[0]?.rules ? JSON.parse(response?.data?.fluxos[0]?.rules) : []);
	};
	const toggleShowDays = (e) => {
		e.preventDefault();
		setShowDays(!showDays);
	};
	const toggleShowMessages = (e) => {
		e.preventDefault();
		setShowMessages(!showMessages);
	};
	const toggleShowSubniveis = (e) => {
		e.preventDefault();
		setShowSubniveis(!showSubniveis);
	};
	const resetMedia = () => {
		setMedia(null);
	};

	const [loading, setLoading] = useState(false);
	const handleCloseQueueUModal = () => {
		setOpenQueueUploadModal(false);
	};
	useEffect(() => {
		if (queueId) {
			getCondition();
		}
		(async () => {
			if (!queueId) return;
			try {
				const { data } = await api.get(`/queue/${queueId}`);
				setParentId(data.parentId);
				setShowQueue(data.showQueue);
				setMedia(data?.mediaUrl || null)
				setTransferId(data?.transferQueue);
				setInitialLevel(data?.initialLevel);
				const response = await api.get('/openingHours');
				const horarios = response.data.filter(function (e) {
					return e.queueId == queueId
				});
				let sunSchedules = horarios.find(item => item.dayOfTheWeekId == 1);
				let monSchedules = horarios.find(item => item.dayOfTheWeekId == 2);
				let tueSchedules = horarios.find(item => item.dayOfTheWeekId == 3);
				let wedSchedules = horarios.find(item => item.dayOfTheWeekId == 4);
				let thuSchedules = horarios.find(item => item.dayOfTheWeekId == 5);
				let friSchedules = horarios.find(item => item.dayOfTheWeekId == 6);
				let satSchedules = horarios.find(item => item.dayOfTheWeekId == 7);


				data.sunId = sunSchedules.id;
				data.startWorkSunMorning = sunSchedules.startTime;
				data.endWorkSunMorning = sunSchedules.breakTime == "" ? "" : sunSchedules.breakTime.split('-')[0];
				data.startWorkSunAfternoon = sunSchedules.breakTime == "" ? "" : sunSchedules.breakTime.split('-')[1];
				data.endWorkSunAfternoon = sunSchedules.endTime;

				data.monId = monSchedules.id;
				data.startWorkMonMorning = monSchedules.startTime;
				data.endWorkMonMorning = monSchedules.breakTime == "" ? "" : monSchedules.breakTime.split('-')[0];
				data.startWorkMonAfternoon = monSchedules.breakTime == "" ? "" : monSchedules.breakTime.split('-')[1];
				data.endWorkMonAfternoon = monSchedules.endTime;

				data.tueId = tueSchedules.id;
				data.startWorkTueMorning = tueSchedules.startTime;
				data.endWorkTueMorning = tueSchedules.breakTime == "" ? "" : tueSchedules.breakTime.split('-')[0];
				data.startWorkTueAfternoon = tueSchedules.breakTime == "" ? "" : tueSchedules.breakTime.split('-')[1];
				data.endWorkTueAfternoon = tueSchedules.endTime;

				data.wedId = wedSchedules.id;
				data.startWorkWedMorning = wedSchedules.startTime;
				data.endWorkWedMorning = wedSchedules.breakTime == "" ? "" : wedSchedules.breakTime.split('-')[0];
				data.startWorkWedAfternoon = wedSchedules.breakTime == "" ? "" : wedSchedules.breakTime.split('-')[1];
				data.endWorkWedAfternoon = wedSchedules.endTime;

				data.thuId = thuSchedules.id;
				data.startWorkThuMorning = thuSchedules.startTime;
				data.endWorkThuMorning = thuSchedules.breakTime == "" ? "" : thuSchedules.breakTime.split('-')[0];
				data.startWorkThuAfternoon = thuSchedules.breakTime == "" ? "" : thuSchedules.breakTime.split('-')[1];
				data.endWorkThuAfternoon = thuSchedules.endTime;

				data.friId = friSchedules.id;
				data.startWorkFriMorning = friSchedules.startTime;
				data.endWorkFriMorning = friSchedules.breakTime == "" ? "" : friSchedules.breakTime.split('-')[0];
				data.startWorkFriAfternoon = friSchedules.breakTime == "" ? "" : friSchedules.breakTime.split('-')[1];
				data.endWorkFriAfternoon = friSchedules.endTime;

				data.satId = satSchedules.id;
				data.startWorkSatMorning = satSchedules.startTime;
				data.endWorkSatMorning = satSchedules.breakTime == "" ? "" : satSchedules.breakTime.split('-')[0];
				data.startWorkSatAfternoon = satSchedules.breakTime == "" ? "" : satSchedules.breakTime.split('-')[1];
				data.endWorkSatAfternoon = satSchedules.endTime;
				setQueue(prevState => {
					return { ...prevState, ...data };
				});
			} catch (err) {
				toastError(err);
			}
		})();

		return () => {
			setQueue({
				name: "",
				color: "",
				position: "",
				greetingMessage: "",
				startWork: "",
				endWork: "",
				absenceMessage: "",
				startWorkSunMorning: "00:00",
				endWorkSunAfternoon: "23:59",
				startWorkMonMorning: "00:00",
				endWorkMonMorning: "",
				startWorkMonAfternoon: "",
				endWorkMonAfternoon: "23:59",
				startWorkTueMorning: "00:00",
				endWorkTueMorning: "",
				startWorkTueAfternoon: "",
				endWorkTueAfternoon: "23:59",
				startWorkWedMorning: "00:00",
				endWorkWedMorning: "",
				startWorkWedAfternoon: "",
				endWorkWedAfternoon: "23:59",
				startWorkThuMorning: "00:00",
				endWorkThuMorning: "",
				startWorkThuAfternoon: "",
				endWorkThuAfternoon: "23:59",
				startWorkFriMorning: "00:00",
				endWorkFriMorning: "",
				startWorkFriAfternoon: "",
				endWorkFriAfternoon: "23:59",
				startWorkSatMorning: "00:00",
				endWorkSatAfternoon: "23:59",

			});
		};

	}, [queueId, open]);

	const handleClose = () => {
		onClose();
		setConditions([])
		setFluxoId(null);
		setQueue(initialState);
	};

	const handleChangeConditions = async(result) => {
		setConditions(result);
		handleSaveQueue(queue, true)
	
	}
	
	const handleSaveQueue = async (values, preventExitModal) => {
		setLoading(true);
		const mediaUrl = media?.id || null;
		const valuesAtualizados = { ...values, parentId, showQueue, mediaUrl, transferQueue: transferId, fluxoId: fluxoId || null};
		try {
			let form = {
				dayOfTheWeekId: null,
				breakTime: '',
				startTime: '',
				endTime: '',
			}
			if (queueId) {
        if(fluxoId){
          await api.put(`/fluxo/${fluxoId}`, {rules: conditions});
        }
		
				await api.put(`/queue/${queueId}`, valuesAtualizados);
				for (let i = 0; i < 7; i++) {
					form.dayOfTheWeekId = i + 1;
					form.breakTime = '';
					form.hourId = null;
					switch (i) {
						case 0:
							if (values.endWorkSunMorning
								&& values.endWorkSunMorning != ''
								&& values.startWorkSunAfternoon
								&& values.startWorkSunAfternoon != '') {
								form.breakTime = values.endWorkSunMorning + '-' + values.startWorkSunAfternoon;
							}
							form.hourId = values.sunId;
							form.startTime = values.startWorkSunMorning;
							form.endTime = values.endWorkSunAfternoon;
							break;
						case 1:
							if (values.endWorkMonMorning
								&& values.endWorkMonMorning != ''
								&& values.startWorkMonAfternoon
								&& values.startWorkMonAfternoon != '') {
								form.breakTime = values.endWorkMonMorning + '-' + values.startWorkMonAfternoon;
							}
							form.hourId = values.monId;
							form.startTime = values.startWorkMonMorning;
							form.endTime = values.endWorkMonAfternoon;
							break;
						case 2:
							if (values.endWorkTueMorning
								&& values.endWorkTueMorning != ''
								&& values.startWorkTueAfternoon
								&& values.startWorkTueAfternoon != '') {
								form.breakTime = values.endWorkTueMorning + '-' + values.startWorkTueAfternoon;
							}
							form.hourId = values.tueId;
							form.startTime = values.startWorkTueMorning;
							form.endTime = values.endWorkTueAfternoon;
							break;
						case 3:
							if (values.endWorkWedMorning
								&& values.endWorkWedMorning != ''
								&& values.startWorkWedAfternoon
								&& values.startWorkWedAfternoon != '') {
								form.breakTime = values.endWorkWedMorning + '-' + values.startWorkWedAfternoon;
							}
							form.hourId = values.wedId;
							form.startTime = values.startWorkWedMorning;
							form.endTime = values.endWorkWedAfternoon;
							break;
						case 4:
							if (values.endWorkThuMorning
								&& values.endWorkThuMorning != ''
								&& values.startWorkThuAfternoon
								&& values.startWorkThuAfternoon != '') {
								form.breakTime = values.endWorkThuMorning + '-' + values.startWorkThuAfternoon;
							}
							form.hourId = values.thuId;
							form.startTime = values.startWorkThuMorning;
							form.endTime = values.endWorkThuAfternoon;
							break;
						case 5:
							if (values.endWorkFriMorning
								&& values.endWorkFriMorning != ''
								&& values.startWorkFriAfternoon
								&& values.startWorkFriAfternoon != '') {
								form.breakTime = values.endWorkFriMorning + '-' + values.startWorkFriAfternoon;
							}
							form.hourId = values.friId;
							form.startTime = values.startWorkFriMorning;
							form.endTime = values.endWorkFriAfternoon;
							break;
						case 6:
							if (values.endWorkSatMorning
								&& values.endWorkSatMorning != ''
								&& values.startWorkSatAfternoon
								&& values.startWorkSatAfternoon != '') {
								form.breakTime = values.endWorkSatMorning + '-' + values.startWorkSatAfternoon;
							}
							form.hourId = values.satId;
							form.startTime = values.startWorkSatMorning;
							form.endTime = values.endWorkSatAfternoon;
							break;
					}

					api.put(`/openingHours/${form.hourId}`, form);
				}
			} else {
				valuesAtualizados.position = nextId || "";
				let response = await api.post("/queue", valuesAtualizados);
				if (response.status == 200) {
					form.queueId = response.data.id;
					for (let i = 0; i < 7; i++) {
						form.dayOfTheWeekId = i + 1;
						form.breakTime = '';
						switch (i) {
							case 0:
								if (values.endWorkSunMorning
									&& values.endWorkSunMorning != ''
									&& values.startWorkSunAfternoon
									&& values.startWorkSunAfternoon != '') {
									form.breakTime = values.endWorkSunMorning + '-' + values.startWorkSunAfternoon;
								}
								form.startTime = values.startWorkSunMorning;
								form.endTime = values.endWorkSunAfternoon;
								break;
							case 1:
								if (values.endWorkMonMorning
									&& values.endWorkMonMorning != ''
									&& values.startWorkMonAfternoon
									&& values.startWorkMonAfternoon != '') {
									form.breakTime = values.endWorkMonMorning + '-' + values.startWorkMonAfternoon;
								}
								form.startTime = values.startWorkMonMorning;
								form.endTime = values.endWorkMonAfternoon;
								break;
							case 2:
								if (values.endWorkTueMorning
									&& values.endWorkTueMorning != ''
									&& values.startWorkTueAfternoon
									&& values.startWorkTueAfternoon != '') {
									form.breakTime = values.endWorkTueMorning + '-' + values.startWorkTueAfternoon;
								}
								form.startTime = values.startWorkTueMorning;
								form.endTime = values.endWorkTueAfternoon;
								break;
							case 3:
								if (values.endWorkWedMorning
									&& values.endWorkWedMorning != ''
									&& values.startWorkWedAfternoon
									&& values.startWorkWedAfternoon != '') {
									form.breakTime = values.endWorkWedMorning + '-' + values.startWorkWedAfternoon;
								}
								form.startTime = values.startWorkWedMorning;
								form.endTime = values.endWorkWedAfternoon;
								break;
							case 4:
								if (values.endWorkThuMorning
									&& values.endWorkThuMorning != ''
									&& values.startWorkThuAfternoon
									&& values.startWorkThuAfternoon != '') {
									form.breakTime = values.endWorkThuMorning + '-' + values.startWorkThuAfternoon;
								}
								form.startTime = values.startWorkThuMorning;
								form.endTime = values.endWorkThuAfternoon;
								break;
							case 5:
								if (values.endWorkFriMorning
									&& values.endWorkFriMorning != ''
									&& values.startWorkFriAfternoon
									&& values.startWorkFriAfternoon != '') {
									form.breakTime = values.endWorkFriMorning + '-' + values.startWorkFriAfternoon;
								}
								form.startTime = values.startWorkFriMorning;
								form.endTime = values.endWorkFriAfternoon;
								break;
							case 6:
								if (values.endWorkSatMorning
									&& values.endWorkSatMorning != ''
									&& values.startWorkSatAfternoon
									&& values.startWorkSatAfternoon != '') {
									form.breakTime = values.endWorkSatMorning + '-' + values.startWorkSatAfternoon;
								}
								form.startTime = values.startWorkSatMorning;
								form.endTime = values.endWorkSatAfternoon;
								break;
						}

						api.post("/openingHours", form);
					}
				}
			}
			toast.success(`${i18n.t("queueModal.notification.title")}`);
			if(!preventExitModal){
				handleClose();
			}
			
		} catch (err) {
			toastError(err);
		}
		setLoading(false);
	};
	return (
		<div className={classes.root}>
			<Dialog open={open} onClose={handleClose} scroll="paper">
				<DialogTitle>
					{queueId
						? `${i18n.t("queueModal.title.edit")}`
						: `${i18n.t("queueModal.title.add")}`}


				</DialogTitle>
				<Formik
					initialValues={queue}
					enableReinitialize={true}
					validationSchema={QueueSchema}
					onSubmit={async (values, actions) => {
							await handleSaveQueue(values);
							actions.setSubmitting(false);
						
					}}
				>
					{({ touched, errors, isSubmitting, values }) => (

						<Form>
							<DialogContent dividers>

								<Field
									as={TextField}
									label={i18n.t("queueModal.form.name")}
									autoFocus
									name="name"
									error={touched.name && Boolean(errors.name)}
									helperText={touched.name && errors.name}
									variant="outlined"
									margin="dense"
									style={{ width: '98%' }}
									className={classes.textField}
								/>
								<Field
									as={TextField}
									label={i18n.t("queueModal.form.color")}
									name="color"
									id="color"
									onFocus={() => {
										if (greetingRef.current) {
											setColorPickerModalOpen(true);
											greetingRef.current.focus();
										}
									}}
									error={touched.color && Boolean(errors.color)}
									helperText={touched.color && errors.color}
									InputProps={{
										startAdornment: (
											<InputAdornment position="start">
												<div
													style={{ backgroundColor: values.color }}
													className={classes.colorAdorment}
												></div>
											</InputAdornment>
										),
										endAdornment: (
											<IconButton
												size="small"
												color="default"
												onClick={() => setColorPickerModalOpen(true)}
											>
												<Colorize />
											</IconButton>
										),
									}}
									variant="outlined"
									margin="dense"

								/>
								<ColorPicker
									open={colorPickerModalOpen}
									handleClose={() => setColorPickerModalOpen(false)}
									onChange={color => {
										values.color = color;
										setQueue(() => {
											return { ...values, color };
										});
									}}
								/>
								<FormControl variant="outlined" margin="dense" className={classes.textField} style={{ width: '50%' }}>
									<InputLabel>Status</InputLabel>
									<Select
										value={showQueue}
										className={classes.autoComplete}
										onChange={(e) => setShowQueue(e.target.value)}
										label=""

									>
										<MenuItem key={true} value={true}>Ativado</MenuItem>
										<MenuItem key={false} value={false}>Desativado</MenuItem>
									</Select>
								</FormControl>

								{/* <Field
										as={TextField}
										label={i18n.t("queueModal.form.position")}
										autoFocus
										name="position"
										error={touched.position && Boolean(errors.position)}
										helperText={touched.position && errors.position}
										variant="outlined"
										margin="dense"
										className={classes.position}
									/> */}
								<Button
									style={{ marginTop: 5 }}
									fullWidth
									variant="outlined"
									color="primary"
									onClick={toggleShowDays}
									endIcon={showDays ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
								>
									{showDays ? 'Ocultar' : 'Mostrar'} Horários
								</Button>
								{showDays && (<section>
									<form className={classes.container} noValidate>
										<div className={classes.dates}>
											Segunda:
										</div>

										<Field
											as={TextField}
											label={i18n.t("queueModal.form.startWorkMorning")}
											type="time"
											ampm={false}
											defaultValue=""
											inputRef={startWorkMonMorningRef}
											InputLabelProps={{
												shrink: true,
											}}
											inputProps={{
												step: 600, // 5 min
											}}
											fullWidth
											name="startWorkMonMorning"
											error={
												touched.startWorkMonMorning && Boolean(errors.startWorkMonMorning)
											}
											helperText={
												touched.startWorkMonMorning && errors.startWorkMonMorning
											}
											variant="outlined"
											margin="dense"
											className={classes.textField}
										/>
										<Field
											as={TextField}
											label={i18n.t("queueModal.form.endWorkMorning")}
											type="time"
											ampm={false}
											defaultValue=""
											inputRef={endWorkMonMorningRef}
											InputLabelProps={{
												shrink: true,
											}}
											inputProps={{
												step: 600, // 5 min
											}}
											fullWidth
											name="endWorkMonMorning"
											error={
												touched.endWorkMonMorning && Boolean(errors.endWorkMonMorning)
											}
											helperText={
												touched.endWorkMonMorning && errors.endWorkMonMorning
											}
											variant="outlined"
											margin="dense"
											className={classes.textField}
										/>
										<Field
											as={TextField}
											label={i18n.t("queueModal.form.startWorkAfternoon")}
											type="time"
											ampm={false}
											defaultValue=""
											inputRef={startWorkMonAfternoonRef}
											InputLabelProps={{
												shrink: true,
											}}
											inputProps={{
												step: 600, // 5 min
											}}
											fullWidth
											name="startWorkMonAfternoon"
											error={
												touched.startWorkMonAfternoon && Boolean(errors.startWorkMonAfternoon)
											}
											helperText={
												touched.startWorkMonAfternoon && errors.startWorkMonAfternoon
											}
											variant="outlined"
											margin="dense"
											className={classes.textField}
										/>
										<Field
											as={TextField}
											label={i18n.t("queueModal.form.endWorkAfternoon")}
											type="time"
											ampm={false}
											defaultValue=""
											inputRef={endWorkMonAfternoonRef}
											InputLabelProps={{
												shrink: true,
											}}
											inputProps={{
												step: 600, // 5 min
											}}
											fullWidth
											name="endWorkMonAfternoon"
											error={
												touched.endWorkMonAfternoon && Boolean(errors.endWorkMonAfternoon)
											}
											helperText={
												touched.endWorkMonAfternoon && errors.endWorkMonAfternoon
											}
											variant="outlined"
											margin="dense"
											className={classes.textField}
										/>
									</form>


									<form className={classes.container} noValidate>
										<div className={classes.dates}>
											Terça:
										</div>

										<Field
											as={TextField}
											label={i18n.t("queueModal.form.startWorkMorning")}
											type="time"
											ampm={false}
											defaultValue=""
											inputRef={startWorkTueMorningRef}
											InputLabelProps={{
												shrink: true,
											}}
											inputProps={{
												step: 600, // 5 min
											}}
											fullWidth
											name="startWorkTueMorning"
											error={
												touched.startWorkTueMorning && Boolean(errors.startWorkTueMorning)
											}
											helperText={
												touched.startWorkTueMorning && errors.startWorkTueMorning
											}
											variant="outlined"
											margin="dense"
											className={classes.textField}
										/>
										<Field
											as={TextField}
											label={i18n.t("queueModal.form.endWorkMorning")}
											type="time"
											ampm={false}
											defaultValue=""
											inputRef={endWorkTueMorningRef}
											InputLabelProps={{
												shrink: true,
											}}
											inputProps={{
												step: 600, // 5 min
											}}
											fullWidth
											name="endWorkTueMorning"
											error={
												touched.endWorkTueMorning && Boolean(errors.endWorkTueMorning)
											}
											helperText={
												touched.endWorkTueMorning && errors.endWorkTueMorning
											}
											variant="outlined"
											margin="dense"
											className={classes.textField}
										/>
										<Field
											as={TextField}
											label={i18n.t("queueModal.form.startWorkAfternoon")}
											type="time"
											ampm={false}
											defaultValue=""
											inputRef={startWorkTueAfternoonRef}
											InputLabelProps={{
												shrink: true,
											}}
											inputProps={{
												step: 600, // 5 min
											}}
											fullWidth
											name="startWorkTueAfternoon"
											error={
												touched.startWorkTueAfternoon && Boolean(errors.startWorkTueAfternoon)
											}
											helperText={
												touched.startWorkTueAfternoon && errors.startWorkTueAfternoon
											}
											variant="outlined"
											margin="dense"
											className={classes.textField}
										/>
										<Field
											as={TextField}
											label={i18n.t("queueModal.form.endWorkAfternoon")}
											type="time"
											ampm={false}
											defaultValue=""
											inputRef={endWorkTueAfternoonRef}
											InputLabelProps={{
												shrink: true,
											}}
											inputProps={{
												step: 600, // 5 min
											}}
											fullWidth
											name="endWorkTueAfternoon"
											error={
												touched.endWorkTueAfternoon && Boolean(errors.endWorkTueAfternoon)
											}
											helperText={
												touched.endWorkTueAfternoon && errors.endWorkTueAfternoon
											}
											variant="outlined"
											margin="dense"
											className={classes.textField}
										/>
									</form>
									<form className={classes.container} noValidate>
										<div className={classes.dates}>
											Quarta:
										</div>
										<Field
											as={TextField}
											label={i18n.t("queueModal.form.startWorkMorning")}
											type="time"
											ampm={false}
											defaultValue=""
											inputRef={startWorkWedMorningRef}
											InputLabelProps={{
												shrink: true,
											}}
											inputProps={{
												step: 600, // 5 min
											}}
											fullWidth
											name="startWorkWedMorning"
											error={
												touched.startWorkWedMorning && Boolean(errors.startWorkWedMorning)
											}
											helperText={
												touched.startWorkWedMorning && errors.startWorkWedMorning
											}
											variant="outlined"
											margin="dense"
											className={classes.textField}
										/>
										<Field
											as={TextField}
											label={i18n.t("queueModal.form.endWorkMorning")}
											type="time"
											ampm={false}
											defaultValue=""
											inputRef={endWorkWedMorningRef}
											InputLabelProps={{
												shrink: true,
											}}
											inputProps={{
												step: 600, // 5 min
											}}
											fullWidth
											name="endWorkWedMorning"
											error={
												touched.endWorkWedMorning && Boolean(errors.endWorkWedMorning)
											}
											helperText={
												touched.endWorkWedMorning && errors.endWorkWedMorning
											}
											variant="outlined"
											margin="dense"
											className={classes.textField}
										/>
										<Field
											as={TextField}
											label={i18n.t("queueModal.form.startWorkAfternoon")}
											type="time"
											ampm={false}
											defaultValue=""
											inputRef={startWorkWedAfternoonRef}
											InputLabelProps={{
												shrink: true,
											}}
											inputProps={{
												step: 600, // 5 min
											}}
											fullWidth
											name="startWorkWedAfternoon"
											error={
												touched.startWorkWedAfternoon && Boolean(errors.startWorkWedAfternoon)
											}
											helperText={
												touched.startWorkWedAfternoon && errors.startWorkWedAfternoon
											}
											variant="outlined"
											margin="dense"
											className={classes.textField}
										/>
										<Field
											as={TextField}
											label={i18n.t("queueModal.form.endWorkAfternoon")}
											type="time"
											ampm={false}
											defaultValue=""
											inputRef={endWorkWedAfternoonRef}
											InputLabelProps={{
												shrink: true,
											}}
											inputProps={{
												step: 600, // 5 min
											}}
											fullWidth
											name="endWorkWedAfternoon"
											error={
												touched.endWorkWedAfternoon && Boolean(errors.endWorkWedAfternoon)
											}
											helperText={
												touched.endWorkWedAfternoon && errors.endWorkWedAfternoon
											}
											variant="outlined"
											margin="dense"
											className={classes.textField}
										/>


									</form>
									<form className={classes.container} noValidate>
										<div className={classes.dates}>
											Quinta:
										</div>
										<Field
											as={TextField}
											label={i18n.t("queueModal.form.startWorkMorning")}
											type="time"
											ampm={false}
											defaultValue=""
											inputRef={startWorkThuMorningRef}
											InputLabelProps={{
												shrink: true,
											}}
											inputProps={{
												step: 600, // 5 min
											}}
											fullWidth
											name="startWorkThuMorning"
											error={
												touched.startWorkThuMorning && Boolean(errors.startWorkThuMorning)
											}
											helperText={
												touched.startWorkThuMorning && errors.startWorkThuMorning
											}
											variant="outlined"
											margin="dense"
											className={classes.textField}
										/>
										<Field
											as={TextField}
											label={i18n.t("queueModal.form.endWorkMorning")}
											type="time"
											ampm={false}
											defaultValue=""
											inputRef={endWorkThuMorningRef}
											InputLabelProps={{
												shrink: true,
											}}
											inputProps={{
												step: 600, // 5 min
											}}
											fullWidth
											name="endWorkThuMorning"
											error={
												touched.endWorkThuMorning && Boolean(errors.endWorkThuMorning)
											}
											helperText={
												touched.endWorkThuMorning && errors.endWorkThuMorning
											}
											variant="outlined"
											margin="dense"
											className={classes.textField}
										/>
										<Field
											as={TextField}
											label={i18n.t("queueModal.form.startWorkAfternoon")}
											type="time"
											ampm={false}
											defaultValue=""
											inputRef={startWorkThuAfternoonRef}
											InputLabelProps={{
												shrink: true,
											}}
											inputProps={{
												step: 600, // 5 min
											}}
											fullWidth
											name="startWorkThuAfternoon"
											error={
												touched.startWorkThuAfternoon && Boolean(errors.startWorkThuAfternoon)
											}
											helperText={
												touched.startWorkThuAfternoon && errors.startWorkThuAfternoon
											}
											variant="outlined"
											margin="dense"
											className={classes.textField}
										/>
										<Field
											as={TextField}
											label={i18n.t("queueModal.form.endWorkAfternoon")}
											type="time"
											ampm={false}
											defaultValue=""
											inputRef={endWorkThuAfternoonRef}
											InputLabelProps={{
												shrink: true,
											}}
											inputProps={{
												step: 600, // 5 min
											}}
											fullWidth
											name="endWorkThuAfternoon"
											error={
												touched.endWorkThuAfternoon && Boolean(errors.endWorkThuAfternoon)
											}
											helperText={
												touched.endWorkThuAfternoon && errors.endWorkThuAfternoon
											}
											variant="outlined"
											margin="dense"
											className={classes.textField}
										/>
									</form>
									<form className={classes.container} noValidate>
										<div className={classes.dates}>
											Sexta:
										</div>
										<Field
											as={TextField}
											label={i18n.t("queueModal.form.startWorkMorning")}
											type="time"
											ampm={false}
											defaultValue=""
											inputRef={startWorkFriMorningRef}
											InputLabelProps={{
												shrink: true,
											}}
											inputProps={{
												step: 600, // 5 min
											}}
											fullWidth
											name="startWorkFriMorning"
											error={
												touched.startWorkFriMorning && Boolean(errors.startWorkFriMorning)
											}
											helperText={
												touched.startWorkFriMorning && errors.startWorkFriMorning
											}
											variant="outlined"
											margin="dense"
											className={classes.textField}
										/>
										<Field
											as={TextField}
											label={i18n.t("queueModal.form.endWorkMorning")}
											type="time"
											ampm={false}
											defaultValue=""
											inputRef={endWorkFriMorningRef}
											InputLabelProps={{
												shrink: true,
											}}
											inputProps={{
												step: 600, // 5 min
											}}
											fullWidth
											name="endWorkFriMorning"
											error={
												touched.endWorkFriMorning && Boolean(errors.endWorkFriMorning)
											}
											helperText={
												touched.endWorkFriMorning && errors.endWorkFriMorning
											}
											variant="outlined"
											margin="dense"
											className={classes.textField}
										/>
										<Field
											as={TextField}
											label={i18n.t("queueModal.form.startWorkAfternoon")}
											type="time"
											ampm={false}
											defaultValue=""
											inputRef={startWorkFriAfternoonRef}
											InputLabelProps={{
												shrink: true,
											}}
											inputProps={{
												step: 600, // 5 min
											}}
											fullWidth
											name="startWorkFriAfternoon"
											error={
												touched.startWorkFriAfternoon && Boolean(errors.startWorkFriAfternoon)
											}
											helperText={
												touched.startWorkFriAfternoon && errors.startWorkFriAfternoon
											}
											variant="outlined"
											margin="dense"
											className={classes.textField}
										/>
										<Field
											as={TextField}
											label={i18n.t("queueModal.form.endWorkAfternoon")}
											type="time"
											ampm={false}
											defaultValue=""
											inputRef={endWorkFriAfternoonRef}
											InputLabelProps={{
												shrink: true,
											}}
											inputProps={{
												step: 600, // 5 min
											}}
											fullWidth
											name="endWorkFriAfternoon"
											error={
												touched.endWorkFriAfternoon && Boolean(errors.endWorkFriAfternoon)
											}
											helperText={
												touched.endWorkFriAfternoon && errors.endWorkFriAfternoon
											}
											variant="outlined"
											margin="dense"
											className={classes.textField}
										/>
									</form>
									<form className={classes.container} noValidate>
										<div className={classes.dates}>
											Sábado:
										</div>
										<Field
											as={TextField}
											label={i18n.t("queueModal.form.startWorkMorning")}
											type="time"
											ampm={false}
											defaultValue=""
											inputRef={startWorkSatMorningRef}
											InputLabelProps={{
												shrink: true,
											}}
											inputProps={{
												step: 600, // 5 min
											}}
											fullWidth
											name="startWorkSatMorning"
											error={
												touched.startWorkSatMorning && Boolean(errors.startWorkSatMorning)
											}
											helperText={
												touched.startWorkSatMorning && errors.startWorkSatMorning
											}
											variant="outlined"
											margin="dense"
											className={classes.textField}
										/>
										<Field
											as={TextField}
											label={i18n.t("queueModal.form.endWorkMorning")}
											type="time"
											ampm={false}
											defaultValue=""
											inputRef={endWorkSatMorningRef}
											InputLabelProps={{
												shrink: true,
											}}
											inputProps={{
												step: 600, // 5 min
											}}
											fullWidth
											name="endWorkSatMorning"
											error={
												touched.endWorkSatMorning && Boolean(errors.endWorkSatMorning)
											}
											helperText={
												touched.endWorkSatMorning && errors.endWorkSatMorning
											}
											variant="outlined"
											margin="dense"
											className={classes.textField}
										/>
										<Field
											as={TextField}
											label={i18n.t("queueModal.form.startWorkAfternoon")}
											type="time"
											ampm={false}
											defaultValue=""
											inputRef={startWorkSatAfternoonRef}
											InputLabelProps={{
												shrink: true,
											}}
											inputProps={{
												step: 600, // 5 min
											}}
											fullWidth
											name="startWorkSatAfternoon"
											error={
												touched.startWorkSatAfternoon && Boolean(errors.startWorkSatAfternoon)
											}
											helperText={
												touched.startWorkSatAfternoon && errors.startWorkSatAfternoon
											}
											variant="outlined"
											margin="dense"
											className={classes.textField}
										/>
										<Field
											as={TextField}
											label={i18n.t("queueModal.form.endWorkAfternoon")}
											type="time"
											ampm={false}
											defaultValue=""
											inputRef={endWorkSatAfternoonRef}
											InputLabelProps={{
												shrink: true,
											}}
											inputProps={{
												step: 600, // 5 min
											}}
											fullWidth
											name="endWorkSatAfternoon"
											error={
												touched.endWorkSatAfternoon && Boolean(errors.endWorkSatAfternoon)
											}
											helperText={
												touched.endWorkSatAfternoon && errors.endWorkSatAfternoon
											}
											variant="outlined"
											margin="dense"
											className={classes.textField}
										/>
									</form>
									<form className={classes.container} noValidate>
										<div className={classes.dates}>
											Domingo:
										</div>
										<Field
											as={TextField}
											label={i18n.t("queueModal.form.startWorkMorning")}
											type="time"
											ampm={false}
											defaultValue=""
											inputRef={startWorkSunMorningRef}
											InputLabelProps={{
												shrink: true,
											}}
											inputProps={{
												step: 600, // 5 min
											}}
											fullWidth
											name="startWorkSunMorning"
											error={
												touched.startWorkSunMorning && Boolean(errors.startWorkSunMorning)
											}
											helperText={
												touched.startWorkSunMorning && errors.startWorkSunMorning
											}
											variant="outlined"
											margin="dense"
											className={classes.textField}
										/>
										<Field
											as={TextField}
											label={i18n.t("queueModal.form.endWorkMorning")}
											type="time"
											ampm={false}
											defaultValue=""
											inputRef={endWorkSunMorningRef}
											InputLabelProps={{
												shrink: true,
											}}
											inputProps={{
												step: 600, // 5 min
											}}
											fullWidth
											name="endWorkSunMorning"
											error={
												touched.endWorkSunMorning && Boolean(errors.endWorkSunMorning)
											}
											helperText={
												touched.endWorkSunMorning && errors.endWorkSunMorning
											}
											variant="outlined"
											margin="dense"
											className={classes.textField}
										/>
										<Field
											as={TextField}
											label={i18n.t("queueModal.form.startWorkAfternoon")}
											type="time"
											ampm={false}
											defaultValue=""
											inputRef={startWorkSunAfternoonRef}
											InputLabelProps={{
												shrink: true,
											}}
											inputProps={{
												step: 600, // 5 min
											}}
											fullWidth
											name="startWorkSunAfternoon"
											error={
												touched.startWorkSunAfternoon && Boolean(errors.startWorkSunAfternoon)
											}
											helperText={
												touched.startWorkSunAfternoon && errors.startWorkSunAfternoon
											}
											variant="outlined"
											margin="dense"
											className={classes.textField}
										/>
										<Field
											as={TextField}
											label={i18n.t("queueModal.form.endWorkAfternoon")}
											type="time"
											ampm={false}
											defaultValue=""
											inputRef={endWorkSunAfternoonRef}
											InputLabelProps={{
												shrink: true,
											}}
											inputProps={{
												step: 600, // 5 min
											}}
											fullWidth
											name="endWorkSunAfternoon"
											error={
												touched.endWorkSunAfternoon && Boolean(errors.endWorkSunAfternoon)
											}
											helperText={
												touched.endWorkSunAfternoon && errors.endWorkSunAfternoon
											}
											variant="outlined"
											margin="dense"
											className={classes.textField}
										/>
									</form>
								</section>)}
								<Button
									style={{ marginTop: 5 }}
									fullWidth
									variant="outlined"
									color="primary"
									onClick={toggleShowMessages}
									endIcon={showMessages ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
								>
									{showMessages ? 'Ocultar' : 'Mostrar'} Mensagens
								</Button>
								{showMessages && (<section>
									<div>
										<Field
											as={TextField}
											label={i18n.t("queueModal.form.greetingMessage")}
											type="greetingMessage"
											multiline
											inputRef={greetingRef}
											rows={4}
											fullWidth
											name="greetingMessage"
											error={
												touched.greetingMessage && Boolean(errors.greetingMessage)
											}
											helperText={
												touched.greetingMessage && errors.greetingMessage
											}
											variant="outlined"
											margin="dense"
										/>		<div style={{ display: "flex", justifyContent: "center", margin: 5, padding: 10 }}>

											<Button
												fullWidth
												variant="outlined"
												color="secondary"
												onClick={setOpenQueueUploadModal}
											>
												{media ? 'Alterar Arquivo' : 'Selecionar Arquivo'}
											</Button>
											<QueueUploadModal open={openQueueUploadModal} onClose={handleCloseQueueUModal} selectedItem={setMedia} mediaUrl={media} initialLevel={initialLevel || queueId} resetItem={resetMedia} />
											{/* {media && (<section>
											<div style={{ display: "flex", justifyContent: "center", paddingLeft: 5}}>
												<img style={{borderRadius: 4}} height="45" width="45" src={ media.substring(media.lastIndexOf('.') + 1) === 'pdf' ? 'https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/PDF_file_icon.svg/391px-PDF_file_icon.svg.png': process.env.REACT_APP_BACKEND_URL + '/public/' + media.url} />
											</div>

										
										</section>)} */}
										</div>

									</div>
									<div>

										<Field
											as={TextField}
											label={i18n.t("queueModal.form.absenceMessage")}
											type="absenceMessage"
											multiline
											inputRef={absenceRef}
											rows={2}
											fullWidth
											name="absenceMessage"
											error={
												touched.absenceMessage && Boolean(errors.absenceMessage)
											}
											helperText={
												touched.absenceMessage && errors.absenceMessage
											}
											variant="outlined"
											margin="dense"
										/>
									</div>
								</section>)}
								{queueId &&
									(<Button
										style={{ marginTop: 5 }}
										fullWidth
										variant="outlined"
										color="primary"
										onClick={toggleShowSubniveis}
										endIcon={showSubniveis ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
									>
										{showSubniveis ? 'Ocultar' : 'Mostrar'} Subníveis
									</Button>)}
								{(showSubniveis && queueId) && (
									<section>
										{conditions?.length > 0 ? (
											<ConditionList data={conditions || []} fluxoId={fluxoId} onResult={handleChangeConditions} queueId={queueId} />
										) : (
											<SubQueueList id={queueId} onResult={handleChangeConditions}/>
										)}
									</section>
								)}



								<QueueOneSelect
									transferId={transferId}
									onChange={value => setTransferId(value)}
								/>
							</DialogContent>
							<DialogActions>
								<Button
									onClick={handleClose}
									color="secondary"
									disabled={isSubmitting}
									variant="outlined"
								>
									{i18n.t("queueModal.buttons.cancel")}
								</Button>
								<Button
									type="submit"
									color="primary"
									disabled={isSubmitting}
									variant="contained"
									className={classes.btnWrapper}
								>
									{queueId
										? `${i18n.t("queueModal.buttons.okEdit")}`
										: `${i18n.t("queueModal.buttons.okAdd")}`}
									{isSubmitting && (
										<CircularProgress
											size={24}
											className={classes.buttonProgress}
										/>
									)}
								</Button>
							</DialogActions>
						</Form>
					)}
				</Formik>
			</Dialog>
		</div>
	);
};

export default QueueModal;
