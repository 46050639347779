import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Formik,
  Form,
  Field
} from "formik";
import { Button, Dialog, DialogContent, DialogTitle, Tooltip, TextField } from '@material-ui/core';
import { AddCircleOutline } from '@material-ui/icons';
import DialogActions from "@material-ui/core/DialogActions";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import QueueOneSelect from "../QueueOneSelect";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 5
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  img: {
    maxWidth: '150px',
    maxHeight: '150px',
    cursor: 'pointer',
  },
  square: {
    border: '2px solid green'
  },
  uploadInput: {
    display: 'none',
  }
}));

const QueueNewUploadModal = ({ open, onClose, getImages, archive}) => {
  const classes = useStyles();
  const [id, setId] = useState(null);
  const [media, setMedia] = useState(null);
  const [queueId, setQueueId] = useState(null);
  const [description, setDescription] = useState('');
  const handleClose = () => {
    onClose();
    setMedia(null);
    setDescription('');
  };
  useEffect(() => {
    if (archive) {
      setId(archive?.id)
      setDescription(archive?.description);
      setQueueId(archive?.queueId)
    }
  }, [archive]);
  const handleChangeMedia = (e) => {
    setMedia(e.target.files[0]);
  };
  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };
  const handleSaveMedia = async () => {
    const selectedMedia = media;
    const formData = new FormData();
    formData.append("media", selectedMedia);
    formData.append("description", description);
    if(queueId){
      formData.append("queueId", queueId);
    }
   
    try {
      if(id){
        formData.append("url", archive.url);
        await api.put(`/queueuploads/${id}`, formData);
      }
      else{
        await api.post(`/queueuploads`, formData);
      }
     
      handleClose();
      getImages();
    } catch (err) {
      toastError(err);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      className={classes.modal}
      aria-labelledby="form-dialog-title"
      aria-describedby="form-dialog-description"
    >
      <DialogTitle id="form-dialog-title" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        Novo Arquivo
      </DialogTitle>

      <DialogContent style={{width: '380px'}}>
        <Field
          as={TextField}
          label="Descrição"
          autoFocus
          style={{width: '340px'}}
          name="description"
          variant="outlined"
          margin="dense"
          className={classes.textField}
          value={description}
          onChange={handleDescriptionChange}
        />
        <QueueOneSelect
          title={'Vincular a uma Fila'}
          msgPersonalizada={'Vincular a todos'}
          style={{width: '330px'}}
          className={classes.textField}
          transferId={queueId}
          onChange={value => setQueueId(value)}
        />
        <Tooltip title="Adicionar Imagem">
          <label htmlFor="upload-button"  className={classes.textField}>
            <Button component="span" variant="contained" color="primary"    style={{width: '98%', marginTop: '10px'}}>
              {!media ? 'Realizar Upload' : 'Trocar Arquivo'}    <AddCircleOutline />
            </Button>
          </label>
        </Tooltip>
        <input
          type="file"
          id="upload-button"
          className={classes.uploadInput}
          onChange={handleChangeMedia}
        />
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose} color="secondary" variant="outlined">
          Cancelar
        </Button>
        <Button onClick={handleSaveMedia} color="primary" variant="contained">
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default QueueNewUploadModal;
