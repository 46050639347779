import React, { useEffect, useReducer, useState } from "react";
import {
    Button,
    IconButton,
    makeStyles,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    Tooltip
} from "@material-ui/core";
import { green } from "@material-ui/core/colors";

import {
    DeleteOutline,
    Edit,
    AddCircleOutline,
    CropFree
} from "@material-ui/icons";

import MainContainer from "../MainContainer";
import MainHeader from "../MainHeader";
import MainHeaderButtonsWrapper from "../MainHeaderButtonsWrapper";
import TableRowSkeleton from "../TableRowSkeleton";
import Title from "../Title";
import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import { toast } from "react-toastify";

import SuiteModal from "../SuiteModal";
import ConfirmationModal from "../ConfirmationModal";

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        flexWrap: "wrap",
    },
    textField: {
        marginRight: theme.spacing(1),
        flex: 1,
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    btnWrapper: {
        position: "relative",
    },
    buttonProgress: {
        color: green[500],
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
    },

    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },

    colorAdorment: {
        width: 20,
        height: 20,
    },
    dates: {
        margin: 20,
        width: 50
    },
    position: {
        marginRight: theme.spacing(1),
        width: 120
    }
}));

const SuiteList = ({ id, onResult }) => {
    const classes = useStyles();

    const [suites, setSuites] = useState([]);
    const [loading, setLoading] = useState(false);
    const [suiteModalOpen, setSuiteModalOpen] = useState(false);
    const [selectedSuite, setSelectedSuite] = useState(null);
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);

    const handleSuiteModalOpen = async () => {
        setSuiteModalOpen(true);
        setSelectedSuite(null);
    }
    const handleCloseConfirmationModal = () => {
        setConfirmModalOpen(false);
        setSelectedSuite(null);
    };
    const handleCloseSuiteModal = () => {
        setSuiteModalOpen(false);
        setSelectedSuite(null);
        handleGetSuites()
    };
    const handleGetSuites = async () => {
        try {
            const { data } = await api.get("/suite", {
                params: {
                    motelId: id
                }
            });
            setSuites(data)
        } catch (err) {
            toastError(err);
        } finally {
            setLoading(false);
        }
    };
    const handleDeleteSuite = async (suiteId) => {
        try {
            await api.delete(`/suite/${suiteId}`);
            toast.success('Suíte Deletada com Sucesso!');
        } catch (err) {
            toastError(err);
        }

        setSelectedSuite(null);
        handleGetSuites()
    };
    const handleEditSuite = (suite) => {
        setSelectedSuite(suite);
        setSuiteModalOpen(true);
    };
    const downloadQrCode = async (motelId, suiteId) => {

        const qrCodeBase64 = await api.post("/api/qrcode", { url: `${process.env.REACT_APP_BACKEND_URL}/api/redirect/${motelId}/${suiteId}` });
        const decodedData = atob(qrCodeBase64.data.split(',')[1]);


        const byteCharacters = decodedData.split('').map(char => char.charCodeAt(0));
        const byteArray = new Uint8Array(byteCharacters);

        const blob = new Blob([byteArray], { type: 'image/png' });


        const url = URL.createObjectURL(blob);


        const link = document.createElement('a');
        link.href = url;
        link.download = `qr_code_${motelId}_${suiteId}.png`;
        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        URL.revokeObjectURL(url);
    };
    useEffect(() => {
        (async () => {
            if (id) {
                setLoading(true);
                await handleGetSuites()
            }

        })();
    }, []);
    return (
        <MainContainer>
            <ConfirmationModal
                title={
                    selectedSuite &&
                    `Deseja deletar a suite ${selectedSuite.roomNumber
                    }?`
                }
                open={confirmModalOpen}
                onClose={handleCloseConfirmationModal}
                onConfirm={() => handleDeleteSuite(selectedSuite.id)}
            >
                Essa ação não pode ser revertida!
            </ConfirmationModal>
            <SuiteModal
                open={suiteModalOpen}
                onClose={handleCloseSuiteModal}
                suiteId={selectedSuite?.id}
                motelId={id}
            />
            <MainHeader>
                <Title>Opções ({suites.length})</Title>
                <MainHeaderButtonsWrapper>
                    <Tooltip title="Adicionar Suíte">
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleSuiteModalOpen}
                        >
                            <AddCircleOutline />
                        </Button>
                    </Tooltip>
                </MainHeaderButtonsWrapper>
            </MainHeader>
            <Paper className={classes.mainPaper} variant="outlined">
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            {/* <TableCell>
           
                </TableCell> */}
                            <TableCell align="center">
                                Número Suíte
                            </TableCell>
                            <TableCell align="center">
                                Ações
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <>
                            {suites.map((suite, index) => (
                                <TableRow key={suite.id}>
                                    <TableCell align="center">{suite.roomNumber}</TableCell>
                                    <TableCell align="center">
                                        <IconButton
                                            size="small"
                                            onClick={() => {
                                                downloadQrCode(id, suite.roomNumber);
                                            }}
                                        >
                                            <CropFree color="secondary" />
                                        </IconButton>
                                        <IconButton
                                            size="small"
                                            onClick={() => handleEditSuite(suite)}
                                        >
                                            <Edit color="secondary" />
                                        </IconButton>

                                        <IconButton
                                            size="small"
                                            onClick={() => {
                                                setSelectedSuite(suite);
                                                setConfirmModalOpen(true);
                                            }}
                                        >
                                            <DeleteOutline color="secondary" />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                            {loading && <TableRowSkeleton columns={3} />}
                        </>
                    </TableBody>
                </Table>
            </Paper>
        </MainContainer>
    );
};
export default SuiteList;