import React, { useState, useEffect, useRef } from "react";

import * as Yup from "yup";
import {
	Formik,
	Form,
	Field
} from "formik";

import {
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	makeStyles,
	TextField,
	MenuItem,
	Select,
	FormControl,
	InputLabel
} from "@material-ui/core";

import { green } from "@material-ui/core/colors";
import { toast } from "react-toastify";
import { i18n } from "../../translate/i18n";

import api from "../../services/api";
import toastError from "../../errors/toastError";

import SuitesList from "../SuitesList";
import useWhatsApps from "../../hooks/useWhatsApps"


const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		flexWrap: "wrap",
	},
	textField: {
		marginRight: theme.spacing(1),
		flex: 1,
		width: '40vh'
	},
	container: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	btnWrapper: {
		position: "relative",
	},
	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},

	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},

	colorAdorment: {
		width: 20,
		height: 20,
	},
	dates: {
		margin: 20,
		width: 50
	},
	customMsg: {
		width: '85vh'
	},
	position: {
		marginRight: theme.spacing(1),
		width: 150
	},
	maxWidth: {
		width: "100%",
	},

}));

const MotelSchema = Yup.object().shape({
	name: Yup.string()
		.min(2, "Too Short!")
		.max(50, "Too Long!")
		.required("Required")
});

const MotelModal = ({ open, onClose, motelId }) => {
	const classes = useStyles();

	const initialState = {
		name: "",
	};
	const { whatsApps } = useWhatsApps();
	const [selectedWhatsapp, setSelectedWhatsapp] = useState(1);
	const [selectedFluxo, setSelectedFluxo] = useState(1);
	const [motel, setMotel] = useState(initialState);
	const [queues, setQueues] = useState([]);

	useEffect(() => {
		(async () => {
			if (!motelId) return;
			try {
				const { data } = await api.get(`/motel/${motelId}`);
				if (data.whatsappId) {
					setSelectedWhatsapp(data.whatsappId)
				}
				if (data.queueId) {
					setSelectedFluxo(data.queueId)
				}
				setMotel(prevState => {
					return { ...prevState, ...data };
				});
			} catch (err) {
				toastError(err);
			}
		})();

		return () => {
			setMotel({
				name: ""
			});
		};
	}, [motelId, open]);

	const handleClose = () => {
		console.log('close')
		onClose();
		setMotel(initialState);
	};
	const handleSaveMotel = async values => {
		const form = {
			...values,
			whatsappId: selectedWhatsapp,
			queueId: selectedFluxo
		}
		try {
			if (motelId) {
				await api.put(`/motel/${motelId}`, form);
				toast.success(`Motel Atualizado com sucesso!`);
			} else {
				await api.post("/motel", form);
				toast.success(`Motel Cadastrado com sucesso!`);
			}
			handleClose();
		} catch (err) {
			toastError(err);
		}
	};
	useEffect(() => {
		(async () => {
			const { data } = await api.get(`/queue`);
			const filteredQueues = data.filter(item => item.initialLevel === null)
			setQueues(filteredQueues)
		})();
	}, []);
	return (
		<div className={classes.root}>
			<Dialog open={open} onClose={handleClose} scroll="paper">
				<DialogTitle>
					{motelId
						? `Editar Motel`
						: `Adicionar Motel`}


				</DialogTitle>
				<Formik
					initialValues={motel}
					enableReinitialize={true}
					validationSchema={MotelSchema}
					onSubmit={(values, actions) => {
						setTimeout(() => {
							handleSaveMotel(values);
							actions.setSubmitting(false);
						}, 400);
					}}
				>
					{({ touched, errors, isSubmitting, values }) => (

						<Form>
							<DialogContent dividers>
								<Field
									as={TextField}
									label="Descrição"
									autoFocus
									name="name"
									error={touched.name && Boolean(errors.name)}
									helperText={touched.name && errors.name}
									variant="outlined"
									margin="dense"
									className={classes.textField}
								/>
								<FormControl variant="outlined" className={classes.maxWidth} style={{ marginTop: 20 }}>
									<InputLabel>Selecione uma conexão</InputLabel>
									<Select
										value={selectedWhatsapp}
										onChange={(e) => setSelectedWhatsapp(e.target.value)}
										label="Selecione uma conexão"
									>
										{whatsApps.map((whasapp) => (
											<MenuItem key={whasapp.id} value={whasapp.id}>{whasapp.name}</MenuItem>
										))}
									</Select>
								</FormControl>
								<FormControl variant="outlined" className={classes.maxWidth} style={{ marginTop: 20 }}>
									<InputLabel>Selecione um Fluxo</InputLabel>
									<Select
										value={selectedFluxo}
										onChange={(e) => setSelectedFluxo(e.target.value)}
										label="Selecione uma Fluxo"
									>
										{queues.map((queue) => (
											<MenuItem key={queue.id} value={queue.id}>{queue.name}</MenuItem>
										))}
									</Select>
								</FormControl>
								{motelId && <SuitesList id={motelId} />}
							</DialogContent>
							<DialogActions>
								<Button
									onClick={handleClose}
									color="secondary"
									disabled={isSubmitting}
									variant="outlined"
								>
									Cancelar
								</Button>
								<Button
									type="submit"
									color="primary"
									disabled={isSubmitting}
									variant="contained"
									className={classes.btnWrapper}
								>
									{motelId
										? `Editar`
										: `Adicionar`}
									{isSubmitting && (
										<CircularProgress
											size={24}
											className={classes.buttonProgress}
										/>
									)}
								</Button>
							</DialogActions>
						</Form>
					)}
				</Formik>
			</Dialog>
		</div>
	);
};

export default MotelModal;
