import React, { useEffect, useReducer, useState } from "react";
import {
    Button,
    IconButton,
    makeStyles,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    Tooltip
} from "@material-ui/core";
import { green } from "@material-ui/core/colors";

import openSocket from "../../services/socket-io";
import {
    DeleteOutline,
    Edit,
    AddCircleOutline,
    CheckCircle,
    KeyboardArrowDown,
    KeyboardArrowUp,
} from "@material-ui/icons";

import ConfirmationModal from "../../components/ConfirmationModal";
import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import QueueModal from "../../components/QueueModal";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import Title from "../../components/Title";
import QueueOrConditionModal from "../../components/QueueOrConditionModal";
import ConditionModal from "../../components/ConditionModal";
import { i18n } from "../../translate/i18n";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import { toast } from "react-toastify";
// import { Edit, DeleteOutline } from '@material-ui/icons';
const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        flexWrap: "wrap",
    },
    textField: {
        marginRight: theme.spacing(1),
        flex: 1,
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    btnWrapper: {
        position: "relative",
    },
    buttonProgress: {
        color: green[500],
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
    },

    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },

    colorAdorment: {
        width: 20,
        height: 20,
    },
    dates: {
        margin: 20,
        width: 50
    },
    position: {
        marginRight: theme.spacing(1),
        width: 120
    }
}));
const reducer = (state, action) => {
    if (action.type === "LOAD_QUEUES") {
        const queues = action.payload;
        const newQueues = [];

        queues.forEach((queue) => {
            const queueIndex = state.findIndex((q) => q.id === queue.id);
            if (queueIndex !== -1) {
                state[queueIndex] = queue;
            } else {
                newQueues.push(queue);
            }
        });

        return [...state, ...newQueues];
    }

    if (action.type === "UPDATE_QUEUES") {
        const queue = action.payload;
        const queueIndex = state.findIndex((u) => u.id === queue.id);

        if (queueIndex !== -1) {
            state[queueIndex] = queue;
            return [...state];
        } else {
            return [queue, ...state];
        }
    }

    if (action.type === "DELETE_QUEUE") {
        const queueId = action.payload;
        const queueIndex = state.findIndex((q) => q.id === queueId);
        if (queueIndex !== -1) {
            state.splice(queueIndex, 1);
        }
        return [...state];
    }

    if (action.type === "RESET") {
        return [];
    }
};

const SubQueueList = ({id, onResult}) => {
    const queueId = id;
    const classes = useStyles();

    const [queues, dispatch] = useReducer(reducer, []);
    const [loading, setLoading] = useState(false);

    const [queueModalOpen, setQueueModalOpen] = useState(false);
    const [selectedQueue, setSelectedQueue] = useState(null);
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);
    const [conditionModalOpen, setConditionModalOpen] = useState(false);
    const [queueOrConditionModalOpen, setQueueOrConditionModalOpen] = useState(false);
    useEffect(() => {
        (async () => {
            setLoading(true);
            try {
                const { data } = await api.get("/queue");
                dispatch({ type: "LOAD_QUEUES", payload: data });

                setLoading(false);
            } catch (err) {
                toastError(err);
                setLoading(false);
            }
        })();
    }, []);

    useEffect(() => {
        const socket = openSocket();

        socket.on("queue", (data) => {
            if (data.action === "update" || data.action === "create") {
                dispatch({ type: "UPDATE_QUEUES", payload: data.queue });
            }

            if (data.action === "delete") {
                dispatch({ type: "DELETE_QUEUE", payload: data.queueId });
            }
        });

        return () => {
            socket.disconnect();
        };
    }, []);
    const [filteredQueues, setFilteredQueues] = useState([]);
    const [rowsAltered, setRowsAltered] = useState(false);
    useEffect(() => {
        if (queues) {
            setFilteredQueues(queues.filter(queue => queue.parentId === queueId));
        }
    }, [queues]);
    const handleOpenQueueModal = () => {
        setQueueModalOpen(true);
        setSelectedQueue(null);
    };
    const handleOpenConditionModal = () => {
        setConditionModalOpen(true);
        setSelectedQueue(null);
    };
    const handleQueueOrConditionModalOpen = () => {
        if (filteredQueues.length > 0) {
            return handleOpenQueueModal()
        }
        setQueueOrConditionModalOpen(true);

    };
    const handleCloseQueueModal = () => {
        setQueueModalOpen(false);
        setSelectedQueue(null);
    };

    const handleEditQueue = (queue) => {
        setSelectedQueue(queue);
        setQueueModalOpen(true);
    };


    const handleCloseConfirmationModal = () => {
        setConfirmModalOpen(false);
        setSelectedQueue(null);
    };
    const handleCloseConditionModal = () => {
        setConditionModalOpen(false);
    };

    const handleDeleteQueue = async (queueId) => {
        try {
            await api.delete(`/queue/${queueId}`);
            toast.success(i18n.t("queues.notifications.queueDeleted"));
        } catch (err) {
            toastError(err);
        }
        setSelectedQueue(null);
    };

    const handleSelectQueueOrCondition = async (selected) => {
        switch (selected) {
            case 'queue':
                handleOpenQueueModal();
                break;
            case 'condition':
                handleOpenConditionModal();
                break;
            default:
                handleOpenQueueModal();
                break;
        }
    };
    const handleSaveConditionModal = async (rules) => {
        console.log('Recebi, rules')
        const defaultCondition = [{ conditionId: 'default' + generateRandomHash(16), type: "Transferir", isDefault: true, queueId:queueId }];
        const form = {
            description: "Fluxo " + queueId,
            rules: [...defaultCondition, rules],
            queueId:queueId,
            isDefault: false
        };
        try {
            api.post("/fluxo", form);
            if(onResult){
                onResult(form.rules)
            }
            handleCloseConditionModal()
        }
        catch (error) {
            throw error;
        }
    };
    const moveRow = (fromIndex, toIndex) => {
        setRowsAltered(true);
        const updatedQueues = [...filteredQueues];
        const [movedRow] = updatedQueues.splice(fromIndex, 1);
        updatedQueues.splice(toIndex, 0, movedRow);
        setFilteredQueues(updatedQueues);
    };
    const generateRandomHash = (length) => {
        const charset = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const values = new Uint8Array(length);
        window.crypto.getRandomValues(values);
        let randomString = '';
        for (let i = 0; i < length; i++) {
          randomString += charset[values[i] % charset.length];
        }
        return randomString;
      }
    const updatePositions = async () => {
        try {
            let form = [];
            filteredQueues.forEach((item, index) => {
                form.push({ id: item.id, position: index + 1 })
            })
            await api.post(`/queuepositions`, form);
            toast.success("Ordenação redefinida com sucesso!");
            setRowsAltered(false);
        } catch (err) {
            toastError(err);
        }
    };
    const [open, setOpen] = React.useState(false);
    return (
        <MainContainer>
            <QueueOrConditionModal
                title="Selecione uma Opção para Adicionar:"
                open={queueOrConditionModalOpen}
                onClose={setQueueOrConditionModalOpen}
                onConfirm={handleSelectQueueOrCondition}
            >
            </QueueOrConditionModal>
            <ConfirmationModal
                title={
                    selectedQueue &&
                    `${i18n.t("queues.confirmationModal.deleteTitle")} ${selectedQueue.name
                    }?`
                }
                open={confirmModalOpen}
                onClose={handleCloseConfirmationModal}
                onConfirm={() => handleDeleteQueue(selectedQueue.id)}
            >
                {i18n.t("queues.confirmationModal.deleteMessage")}
            </ConfirmationModal>
            <QueueModal
                open={queueModalOpen}
                onClose={handleCloseQueueModal}
                queueId={selectedQueue?.id}
                nextId={filteredQueues?.length + 1}
                ParentId={queueId}

            />
            <ConditionModal
                queueId={queueId}
                open={conditionModalOpen}
                onClose={handleCloseConditionModal}
                onResult={handleSaveConditionModal}
            />
            <MainHeader>
                <Title>Opções ({filteredQueues.length})</Title>
                <MainHeaderButtonsWrapper>
                    <Tooltip title="Salvar Ordenação">
                        <Button
                            disabled={!rowsAltered}
                            variant="contained"
                            color="primary"
                            onClick={updatePositions}
                        >
                            <CheckCircle />
                        </Button>
                    </Tooltip>
                    <Tooltip title={i18n.t("queues.buttons.add")}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleQueueOrConditionModalOpen}
                        >
                            <AddCircleOutline />
                        </Button>
                    </Tooltip>
                </MainHeaderButtonsWrapper>
            </MainHeader>
            <Paper className={classes.mainPaper} variant="outlined">
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            {/* <TableCell>
           
                </TableCell> */}
                            <TableCell align="center">
                                {i18n.t("queues.table.id")}
                            </TableCell>
                            <TableCell align="center">
                                {i18n.t("queues.table.name")}
                            </TableCell>
                            <TableCell align="center">
                                {i18n.t("queues.table.actions")}
                            </TableCell>
                            <TableCell align="center">
                                Ordenar
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <>
                            {filteredQueues.map((queue, index) => (
                                <TableRow key={queue.id}>
                                    {/* <TableCell>
                      <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                      >
                        {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                      </IconButton>
                    </TableCell> */}
                                    <TableCell align="center">{queue.id}</TableCell>
                                    <TableCell align="center">{queue.name}</TableCell>
                                    <TableCell align="center">
                                        <IconButton
                                            size="small"
                                            onClick={() => handleEditQueue(queue)}
                                        >
                                            <Edit color="secondary" />
                                        </IconButton>

                                        <IconButton
                                            size="small"
                                            onClick={() => {
                                                setSelectedQueue(queue);
                                                setConfirmModalOpen(true);
                                            }}
                                        >
                                            <DeleteOutline color="secondary" />
                                        </IconButton>
                                    </TableCell>
                                    <TableCell align="center">
                                        <IconButton
                                            color="secondary"
                                            size="small"
                                            onClick={() => moveRow(index, index - 1)}
                                            disabled={index === 0}
                                        >
                                            <KeyboardArrowUp />
                                        </IconButton>

                                        <IconButton
                                            color="secondary"
                                            size="small"
                                            onClick={() => moveRow(index, index + 1)}
                                            disabled={index === filteredQueues.length - 1}
                                        >
                                            <KeyboardArrowDown />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                            {loading && <TableRowSkeleton columns={5} />}
                        </>
                    </TableBody>
                </Table>
            </Paper>
        </MainContainer>
    );
};
export default SubQueueList;