import React, { useState, useEffect, useRef } from "react";

import * as Yup from "yup";
import {
	Formik,
	Form,
	Field
} from "formik";

import {
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	makeStyles,
	TextField,
	InputLabel,
	FormControl,
	Select,
	MenuItem,
	Tooltip
} from "@material-ui/core";
import {

	AddCircleOutline,

} from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import { green } from "@material-ui/core/colors";
import { i18n } from "../../translate/i18n";
import QueueOneSelect from "../QueueOneSelect";
import MainHeader from "../MainHeader";
import MainHeaderButtonsWrapper from "../MainHeaderButtonsWrapper";
import SubfluxoModal from "../FluxoModal";
import SubConditionList from "../ConditionList";

import QueueUploadModal from "../QueueUploadModal";

import useQueues from "../../hooks/useQueues";
const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		flexWrap: "wrap",
	},
	textField: {
		marginRight: theme.spacing(1),
		flex: 1,
	},
	container: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	btnWrapper: {
		position: "relative",
	},
	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},

	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},

	colorAdorment: {
		width: 20,
		height: 20,
	},
	dates: {
		margin: 20,
		width: 50
	},
	select: {
		marginRight: theme.spacing(1),
		width: 100
	}
}));


const ConditionModal = ({ open, onClose, selectedCondition, onResult, fluxoId, queueId }) => {
	const classes = useStyles();
	const [condition, setCondition] = useState(null);
	const actionsType = selectedCondition?.conditionId?.includes('default') ? ['Enviar Mensagem', 'Transferir'] : ['Transferir'];
	const receivedMsgType = ['Arquivo', 'Data', 'Hora', 'Número', 'Igual'];
	const transfersType = ['Setor', 'Fluxo'];
	const [containsTexts, setContainsTexts] = useState([]);
	const [fluxos, setFluxos] = useState([]);
	const [transferQueue, setTransferQueue] = useState(null);
	const [transferFluxo, setTransferFluxo] = useState(null)
	const [receivedMsg, setReceivedMsg] = useState(null)
	const [action, setAction] = useState('Transferir');
	const [transferId, setTransferId] = useState(null);
	const [transferType, setTransferType] = useState('Setor');
	const [personalizedMessage, setPersonalizedMessage] = useState('');
	const [equalityMessage, setEqualityMessage] = useState('');
	const [subfluxoModal, setSubfluxoModal] = useState(false);
	const [initialLevel, setInitialLevel] = useState(null)
	const [allQueues, setAllQueues] = useState([]);
	const [queues, setQueues] = useState([]);

	const [openQueueUploadModal, setOpenQueueUploadModal] = useState(false)
	const [media, setMedia] = useState(null);
	const [blockClose, setBlockClose] = useState(false);

	const { findAll: findAllQueues } = useQueues();

	const handleCloseQueueUModal = () => {
		setOpenQueueUploadModal(false);
	};
	useEffect(() => {
		if (selectedCondition) {
			setMedia(selectedCondition?.mediaUrl || null)
			setReceivedMsg(selectedCondition?.type);
			setPersonalizedMessage(selectedCondition?.msg);
			setEqualityMessage(selectedCondition?.equalityMsg);
			setAction(selectedCondition?.action);
			setTransferType(selectedCondition?.transferType);
			setFluxos(selectedCondition?.rules || [])
			setTransferFluxo(selectedCondition?.rules?.find(item => item.conditionId === selectedCondition?.transferId));
			if (transferType === 'Setor') {
				setTransferId(selectedCondition?.transferId)
			}
		}
	}, [selectedCondition]);
	const handleClose = () => {
		setReceivedMsg(null);
		setPersonalizedMessage('');
		setEqualityMessage('');
		setAction('Transferir');
		setTransferType('Setor');
		setTransferId(null)
		setTransferFluxo(null);
		onClose();
	};

	const handleSaveCondition = async () => {

		const mediaUrl = media?.id || null;
		const condition = {
			conditionId: selectedCondition?.conditionId || generateRandomHash(16),
			type: receivedMsg,
			action: action,
			transferType: transferType,
			rules: fluxos,
			mediaUrl,
			msg: personalizedMessage,
			equalityMsg: equalityMessage,
			transferId: transferType === 'Setor' ? transferId : transferFluxo?.conditionId,
			isDefault: selectedCondition?.conditionId?.includes('default') ? true : false
		}
		onResult(condition);
	};
	const generateRandomHash = (length) => {
		const charset = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
		const values = new Uint8Array(length);
		window.crypto.getRandomValues(values);
		let randomString = '';
		for (let i = 0; i < length; i++) {
			randomString += charset[values[i] % charset.length];
		}
		return randomString;
	}
	const handleChangeReceivedMsgType = (event) => {
		// console.log("Received message type changed:", event.target.value);
		setReceivedMsg(event.target.value);
	};

	const handleChangeAction = (event) => {
		// console.log("Action changed:", event.target.value);
		setAction(event.target.value);
	};

	const handleChangeTransferType = (event) => {
		if (event.target.value === 'Fluxo') {
			const defaultCondition = [{ conditionId: 'default' + generateRandomHash(16), type: "Transferir", isDefault: true }];
			setFluxos([...defaultCondition])
		}
		else {
			setTransferFluxo(null)
		}
		setTransferType(event.target.value);

	};

	const handleTestResult = (event) => {
		//
	};

	const handlePersonalizedMessageChange = (event) => {
		setPersonalizedMessage(event.target.value);
	};
	const handleEqualityMessageChange = (event) => {
		setEqualityMessage(event.target.value);
	};
	const handleSubfluxoModalOpen = () => {
		setSubfluxoModal(true);
	}
	const handleSubfluxoModalClose = () => {
		setSubfluxoModal(false);
	}
	const handleSubConditionResult = (result) => {
		const newConditions = [...fluxos];
	
		result.forEach(element => {
			const index = newConditions.findIndex(item => item.conditionId === element.conditionId);
			if (index >= 0) {
				newConditions[index] = element; 
			} else {
				newConditions.push(element); 
			}
		});
	
		const conditionIdsInResult = result.map(element => element.conditionId);
		const updatedConditions = newConditions.filter(item => conditionIdsInResult.includes(item.conditionId));
	
		setFluxos(updatedConditions);
	}
	
	useEffect(() => {
		const loadQueues = async () => {
			const list = await findAllQueues();
			const queueSelected = list.find(item => item.id === queueId)
			setInitialLevel(queueSelected.initialLevel);
			const filteredList = list.filter(item => (!item?.initialLevel) || item.initialLevel === initialLevel);
			const uniqueQueues = filteredList?.reduce((uniqueQueues, queue) => {
				const existingQueue = uniqueQueues?.find(item => item.name === queue.name);
				if (!existingQueue) {
					uniqueQueues.push({ id: queue.id, name: queue.name });
				}
				return uniqueQueues;
			}, []);
			uniqueQueues.sort((a, b) => a.name.localeCompare(b.name));
			setAllQueues(uniqueQueues);
			setQueues(uniqueQueues);
		}
		loadQueues();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	const resetMedia = () => {
		setMedia(null);
	};
	return (
		<div className={classes.root}>
			<Dialog open={open} onClose={handleClose} scroll="paper">
				<DialogTitle>
					{selectedCondition
						? 'Editar Condição'
						: 'Adicionar Condição'}


				</DialogTitle>
				<Formik
					enableReinitialize={true}
				>
					{({ touched, errors, isSubmitting, values }) => {
						return (
							<Form>
								<DialogContent dividers>
									{!selectedCondition?.isDefault && (<FormControl variant="outlined" margin="dense" className={classes.textField} style={{ width: '100%' }}>
										<InputLabel>Ao Receber Mensagem do Tipo:</InputLabel>
										<Select
											value={receivedMsg}
											className={classes.autoComplete}
											label="Ao Receber Mensagem do Tipo:"
											onChange={handleChangeReceivedMsgType}
										>
											{receivedMsgType.map((type, index) => (
												<MenuItem key={index} value={type}>
													{type}
												</MenuItem>
											))}
										</Select>
									</FormControl>)}
									{receivedMsg === 'Igual' && <Field
										as={TextField}
										label="Mensagem de Igualdade"
										fullWidth
										name="message"
										value={equalityMessage}
										onChange={handleEqualityMessageChange}
										variant="outlined"
										margin="dense"
									/>}
									<FormControl variant="outlined" margin="dense" className={classes.textField} style={{ width: '100%' }}>
										<InputLabel>Ação:</InputLabel>
										<Select
											value={action}
											className={classes.autoComplete}
											label="Ação:"
											onChange={handleChangeAction}
										>
											{actionsType.map((type, index) => (
												<MenuItem key={index} value={type}>
													{type}
												</MenuItem>
											))}
										</Select>
									</FormControl>

									{action === 'Transferir' && (<FormControl variant="outlined" margin="dense" className={classes.textField} style={{ width: '100%' }}>
										<InputLabel>Transferir para um:</InputLabel>
										<Select
											value={transferType}
											className={classes.autoComplete}
											label="Tipo de Transferência:"
											onChange={handleChangeTransferType}
										>
											{transfersType.map((type, index) => (
												<MenuItem key={index} value={type}>
													{type}
												</MenuItem>
											))}
										</Select>
									</FormControl>)}
									{/* {(action === 'Transferir' && transferType === 'Setor') && (<QueueOneSelect
										transferId={transferId}
										title="Transferir para:"
										onChange={value => setTransferId(value)}
									/>)} */ (action === 'Transferir' && transferType === 'Setor') && <FormControl variant="outlined" className={classes.maxWidth} style={{ marginTop: 20, width: '100%'}}>
											<InputLabel>{i18n.t("transferTicketModal.fieldQueueLabel")}</InputLabel>
											<Select
												required
												value={transferId}
												onChange={(e) => setTransferId(e.target.value)}
												label="Transferir para:"
											>
												<MenuItem value={''}>&nbsp;</MenuItem>
												{queues.map((queue) => (
													<MenuItem key={queue.id} value={queue.id}>{queue.name}</MenuItem>
												))}
											</Select>
										</FormControl>}
									{(action === 'Transferir' && transferType === 'Fluxo') && (<MainHeader>
										<FormControl variant="outlined" margin="dense" className={classes.textField} style={{ width: '100%' }}>
											<SubConditionList data={fluxos} fluxoId={fluxoId} onResult={handleSubConditionResult} queueId={queueId} /></FormControl>
									</MainHeader>)}
									<Field
										as={TextField}
										label="Mensagem Personalizada"
										multiline
										rows={4}
										fullWidth
										name="message"
										value={personalizedMessage}
										onChange={handlePersonalizedMessageChange}
										variant="outlined"
										margin="dense"
									/>
									<Button
										fullWidth
										variant="outlined"
										color="secondary"
										onClick={setOpenQueueUploadModal}
									>
										{media ? 'Alterar Arquivo' : 'Selecionar Arquivo'}
									</Button>
									<QueueUploadModal open={openQueueUploadModal} onClose={handleCloseQueueUModal} selectedItem={setMedia} mediaUrl={media} resetItem={resetMedia} initialLevel={initialLevel} />
								</DialogContent>
								<DialogActions>
									<Button
										onClick={handleClose}
										color="secondary"
										variant="outlined"
									>
										{i18n.t("queueModal.buttons.cancel")}
									</Button>
									<Button
										color="primary"
										onClick={handleSaveCondition}
										variant="contained"
										className={classes.btnWrapper}
									>
										{selectedCondition
											? `${i18n.t("queueModal.buttons.okEdit")}`
											: `${i18n.t("queueModal.buttons.okAdd")}`}


									</Button>
								</DialogActions>
								<SubfluxoModal open={subfluxoModal} onResult={handleTestResult} />
							</Form>
						)
					}}

				</Formik>


			</Dialog>
		</div>
	);
};


export default ConditionModal;
