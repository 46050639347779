import React, { useState, useEffect, useRef } from "react";

import * as Yup from "yup";
import {
	Formik,
	Form,
	Field
} from "formik";

import {
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	makeStyles,
	TextField,
} from "@material-ui/core";

import { green } from "@material-ui/core/colors";
import { toast } from "react-toastify";

import api from "../../services/api";
import toastError from "../../errors/toastError";


const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		flexWrap: "wrap",
	},
	textField: {
		marginRight: theme.spacing(1),
		flex: 1,
		width: '40vh'
	},
	container: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	btnWrapper: {
		position: "relative",
	},
	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},

	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},

	colorAdorment: {
		width: 20,
		height: 20,
	},
	dates: {
		margin: 20,
		width: 50
	},
	customMsg: {
		width: '85vh'
	},
	position: {
		marginRight: theme.spacing(1),
		width: 150
	}
}));

const SuiteSchema = Yup.object().shape({
	roomNumber: Yup.string()
		.min(2, "Too Short!")
		.max(50, "Too Long!")
		.required("Required")
});

const SuiteModal = ({ open, onClose, motelId, suiteId }) => {
	const classes = useStyles();

	const initialState = {
		roomNumber: "",
	};
	const [suite, setSuite] = useState(initialState);

	useEffect(() => {
		(async () => {
			if (!suiteId) return;
			try {
				const { data } = await api.get(`/suite/${suiteId}`);
				setSuite(prevState => {
					return { ...prevState, ...data };
				});
			} catch (err) {
				toastError(err);
			}
		})();

		return () => {
			setSuite({
				roomNumber: ""
			});
		};
	}, [suiteId, open]);

	const handleClose = () => {
		onClose();
		setSuite(initialState);
	};
	const handleSaveSuite= async values => {
		try {
			if (suiteId) {
				await api.put(`/suite/${suiteId}`, values);
				toast.success(`Suite Atualizada com sucesso!`);
			} else {
        const form = {
        ...values,
        motelId: motelId
      };
				await api.post("/suite", form);
				toast.success(`Suite Cadastrada com sucesso!`);
			}
			handleClose();
		} catch (err) {
			toastError(err);
		}
	};
	return (
		<div className={classes.root}>
			<Dialog open={open} onClose={handleClose} scroll="paper">
				<DialogTitle>
					{suiteId
						? `Editar Suite`
						: `Adicionar Suite` }
						
						
				</DialogTitle>
				<Formik
					initialValues={suite}
					enableReinitialize={true}
					validationSchema={SuiteSchema}
					onSubmit={(values, actions) => {
						setTimeout(() => {
							handleSaveSuite(values);
							actions.setSubmitting(false);
						}, 400);
					}}
				>
					{({ touched, errors, isSubmitting, values }) => (
						
						<Form>
							<DialogContent dividers>
								<Field
									as={TextField}
									label="Número Quarto"
									autoFocus
									name="roomNumber"
									error={touched.roomNumber && Boolean(errors.roomNumber)}
									helperText={touched.roomNumber&& errors.roomNumber}
									variant="outlined"
									margin="dense"
									className={classes.textField}
								/>
							</DialogContent>
							<DialogActions>
								<Button
									onClick={handleClose}
									color="secondary"
									disabled={isSubmitting}
									variant="outlined"
								>
									Cancelar
								</Button>
								<Button
									type="submit"
									color="primary"
									disabled={isSubmitting}
									variant="contained"
									className={classes.btnWrapper}
								>
									{suiteId
										? `Editar`
										: `Adicionar`}
									{isSubmitting && (
										<CircularProgress
											size={24}
											className={classes.buttonProgress}
										/>
									)}
								</Button>
							</DialogActions>
						</Form>
					)}
				</Formik>
			</Dialog>
		</div>
	);
};

export default SuiteModal;
