import React, { useEffect, useReducer, useState } from "react";
import {
    Button,
    IconButton,
    makeStyles,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    Tooltip
} from "@material-ui/core";
import { green } from "@material-ui/core/colors";

import {
    DeleteOutline,
    Edit,
    AddCircleOutline,
    CheckCircle,
    KeyboardArrowDown,
    KeyboardArrowUp,
    InsertEmoticonOutlined,
} from "@material-ui/icons";


import MainContainer from "../MainContainer";
import MainHeader from "../MainHeader";
import MainHeaderButtonsWrapper from "../MainHeaderButtonsWrapper";

import TableRowSkeleton from "../TableRowSkeleton";
import Title from "../Title";

import ConditionModal from "../ConditionModal";
import ConfirmationModal from "../ConfirmationModal";
// import { Edit, DeleteOutline } from '@material-ui/icons';
const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        flexWrap: "wrap",
    },
    textField: {
        marginRight: theme.spacing(1),
        flex: 1,
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    btnWrapper: {
        position: "relative",
    },
    buttonProgress: {
        color: green[500],
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
    },

    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },

    colorAdorment: {
        width: 20,
        height: 20,
    },
    dates: {
        margin: 20,
        width: 50
    },
    position: {
        marginRight: theme.spacing(1),
        width: 120
    }
}));
const ConditionList = ({ data, button, onResult, queueId }) => {
    const [conditions, setConditions] = useState(data);
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);
    const [conditionModalOpen, setConditionModalOpen] = useState(false);
    const [selectedCondition, setSelectedCondition] = useState(null);
    const handleEditCondition = (condition) => {
        setSelectedCondition(condition);
        setConditionModalOpen(true);
    };

    const handleConditionModalOpen = () => {
        setConditionModalOpen(true);
        setSelectedCondition(null);
    }
    const handleConditionModalClose = () => {
        setConditionModalOpen(false);
        setSelectedCondition(null);
    }
    const handleCloseConfirmationModal = () => {
        setConfirmModalOpen(false);
    }
    const handleDeleteCondition = (item) => {
        const arrayRemoved = conditions.filter(condition => condition.conditionId !== item.conditionId)
        setConditions(arrayRemoved);
        if (onResult) {
            onResult(arrayRemoved)
        }
    };
    const handleResult = (result) => {
        const newConditions = [...conditions];
       if(!Array.isArray(result)){
        const index = conditions.findIndex(item => item.conditionId === result.conditionId);
        if (index >= 0) {
            newConditions[index] = result;
        } else {
            newConditions.push(result);
        }
       }else{
        result.forEach(element => {
			const index = newConditions.findIndex(item => item.conditionId === element.conditionId)
			if (index >= 0) {
				newConditions[index] = element;
			} else {
				newConditions.push(element);
			}
		});
       }
        setConditions(newConditions);
        if (onResult) {
            onResult(newConditions)
        }
        handleConditionModalClose()
    }
    return (
        <MainContainer><MainHeader>
            <ConditionModal
                open={conditionModalOpen}
                onClose={handleConditionModalClose}
                selectedCondition={selectedCondition}
                onResult={handleResult}
                queueId={queueId}
            />   <ConfirmationModal
                title="Deseja realmente excluir esse fluxo?"
                open={confirmModalOpen}
                onClose={handleCloseConfirmationModal}
                onConfirm={() => handleDeleteCondition(selectedCondition)}
            >
                Você tem certeza? Essa ação não pode ser revertida!
            </ConfirmationModal>
            <Title>Opções ({conditions?.length})</Title>
            <MainHeaderButtonsWrapper>
                <Tooltip title="Adicionar Condição">
                    {button != false && (
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleConditionModalOpen}

                        >
                            <AddCircleOutline />
                        </Button>)}
                </Tooltip>
            </MainHeaderButtonsWrapper>
        </MainHeader>
            <Paper className={classes.mainPaper} variant="outlined">
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            {/* <TableCell>
           
                </TableCell> */}
                            <TableCell align="center">
                                Tipo
                            </TableCell>
                            <TableCell align="center">
                                Ações
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <>
                            {conditions?.map((condition, index) => (

                                <TableRow key={condition.conditionId}>
                                    <TableCell align="center">{condition.type} {condition.isDefault ? "(Padrão)" : ""}</TableCell>
                                    <TableCell align="center">
                                        <IconButton
                                            size="small"
                                            onClick={() => handleEditCondition(condition)}
                                        >
                                            <Edit color="secondary" />
                                        </IconButton>

                                        {!condition.isDefault && (<IconButton
                                            size="small"
                                            onClick={() => {
                                                setSelectedCondition(condition);
                                                setConfirmModalOpen(true);
                                            }}
                                        >
                                            <DeleteOutline color="secondary" />
                                        </IconButton>)}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </>
                    </TableBody>
                </Table>
            </Paper>
        </MainContainer>
    );
};
export default ConditionList;