import React, { useEffect, useState } from "react";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import "./style.css"
import { Button } from "@material-ui/core"
const QueuesPermissionsModal = ({ selectedQueueIds, onChange }) => {
  const [queues, setQueues] = useState([]);
  const [selectedItems, setSelectedItems] = useState(selectedQueueIds);
  const [expandedItems, setExpandedItems] = useState([]);
  useEffect(() => {
    const fetchQueues = async () => {
      try {
        const { data } = await api.get("/queue");
        setQueues(data);
      } catch (err) {
        toastError(err);
      }
    };

    fetchQueues();
  }, []);
  useEffect(() => {
    setSelectedItems(selectedQueueIds);
  }, [selectedQueueIds]);


  const toggleChildren = (itemId, isChecked) => {
    const childItems = queues.filter(item => item.parentId === itemId);
    for (const item of childItems) {
      const index = selectedQueueIds.indexOf(item.id);
      if (isChecked && index === -1) {
        selectedQueueIds.push(item.id);
      } else if (!isChecked && index !== -1) {
        selectedQueueIds.splice(index, 1);
      }
      toggleChildren(item.id, isChecked);
    }
  };
 
  const handleToggleItem = (itemId) => {
    // Verificar se o item já está em selectedQueueIds
    const index = selectedQueueIds.indexOf(itemId);
    if (index !== -1) {
      // Se o item já estiver selecionado, removê-lo
      selectedQueueIds.splice(index, 1);
      // Desmarcar todos os filhos
      toggleChildren(itemId, false);
    } else {
      // Se o item não estiver selecionado, inseri-lo
      selectedQueueIds.push(itemId);
      // Marcar todos os filhos
      toggleChildren(itemId, true);
    }
  
    onChange(selectedQueueIds);
  };
  
  const handleToggleSubItems = (parentId) => {
    setExpandedItems((prevExpandedItems) => {
      if (prevExpandedItems.includes(parentId)) {
        return prevExpandedItems.filter((id) => id !== parentId);
      } else {
        return [...prevExpandedItems, parentId];
      }
    });
  };

  const hasSubItems = (parentId) => {
    return queues.some(queue => queue.parentId === parentId);
  };

  const renderSubItems = (parentId, depth = 0) => {
    const subItems = queues.filter((queue) => queue.parentId === parentId);
    return subItems.map((subItem) => (
      <div key={subItem.id} style={{ marginLeft: `${depth * 20}px` }}>
        <div
          style={{ cursor: 'pointer' }}
          onClick={() => handleToggleSubItems(subItem.id)}
        >
          {hasSubItems(subItem.id) && (expandedItems.includes(subItem.id) ? '-' : '+')}
          <input
            type="checkbox"
            checked={selectedQueueIds.includes(subItem.id)}
            onChange={() => handleToggleItem(subItem.id)}
          />
          <label>{subItem.name}</label>
        </div>
        {expandedItems.includes(subItem.id) && renderSubItems(subItem.id, depth + 1)}
      </div>
    ));
  };

  return (
    <div className="modal">
      <div className="modal-content">
        {queues.map((queue) => {
          if (!queue.parentId) {
            const isExpanded = expandedItems.includes(queue.id);
            const subItems = queues.filter(subItem => subItem.parentId === queue.id);
            return (
              <div key={queue.id}>
                <div
                  style={{ cursor: 'pointer' }}
                  onClick={() => handleToggleSubItems(queue.id)}
                >
                  {hasSubItems(queue.id) && (isExpanded ? '-' : '+')}
                  <input
                    type="checkbox"
                    checked={selectedQueueIds.includes(queue.id)}
                    onChange={() => handleToggleItem(queue.id)}
                  />
                  <label>{queue.name}</label>
                  {/* {hasSubItems(queue.id) && (
                    <Button style={{ marginLeft: 5}} size="small" variant="outlined" onClick={(e) => handleToggleAllSubItems(queue.id, e)}>
                      {subItems.every(queue => selectedQueueIds.includes(queue.id)) ? 'Desmarcar Todos' : 'Marcar Todos'}
                    </Button>
                  )} */}
                </div>
                {isExpanded && renderSubItems(queue.id, 1)}
              </div>
            );
          }
          return null;
        })}
      </div>
    </div>
  );
};

export default QueuesPermissionsModal;