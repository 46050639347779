import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Dialog, DialogContent, Grid, DialogTitle, Tooltip } from '@material-ui/core';
import { AddCircleOutline } from '@material-ui/icons';
import DialogActions from "@material-ui/core/DialogActions";
import QueueNewUploadModal from '../QueueNewUploadModal';
import api from "../../services/api";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 5
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  img: {
    width: '140px',
    height: '138px',
    cursor: 'pointer',

  },
  square: {
    border: '2px solid green'
  },
  dialogTitle: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const QueueUploadModal = ({ open, onClose, queueId, selectedItem, mediaUrl, initialLevel, resetItem }) => {
  const classes = useStyles();
  const [images, setImages] = useState([]);
  const [openNewUploadModal, setOpenNewUploadModal] = useState(false);
  const [archive, setArchive] = useState(null);
  const getImages = async () => {
    try {

      const response = await api.get(`/queueuploads`);
      const filteredImages = response.data.filter(item => item.queueId === null || item.queueId == initialLevel).map(item => item);
      setImages(filteredImages);
    } catch (error) {
      console.error('Erro ao carregar imagens:', error);
    }
  };
  useEffect(() => {
    getImages();
  }, []);
  const typeArchive = (url) => {
    return url.split('.').pop();
  };
  const imageUrl = (url) => {
    return process.env.REACT_APP_BACKEND_URL + '/public/' + url;
  };
  const handleCloseNewUploadModal = () => {
    setOpenNewUploadModal(false);
    setArchive(null);
  };
  const handleClose = () => {
    console.log('close')
		onClose();
	};
  const handleSelectedItem = (img) => {
    console.log(mediaUrl, img.id)
    if (mediaUrl == img.id) {
      selectedItem(null);
      resetItem()
    
    }
    else {
      selectedItem(img);
    }
    onClose();
  };
  const handleOpenModalEdit = (data) => {
    setArchive(data);
    setOpenNewUploadModal(true);
  };
  const openPdf = (url) => {
    if (typeArchive(url) == 'pdf') {
      window.open(process.env.REACT_APP_BACKEND_URL + '/public/' + url, '_blank')
    }
  };
  const body = (
    <div className={classes.paper}>
      <Grid container spacing={2}>
        {images?.map((image, index) => (
          <Grid item xs={4} key={index} >
            <section className={`${image.id == mediaUrl ? classes.square : ''}`}>
              <div style={{ alignItems: 'center', padding: '2%' }}> {image.description} </div>
              {typeArchive(image.url) != 'mp4' ? (<img onClick={() => openPdf(image.url)}
                src={typeArchive(image.url) !== 'pdf' ? imageUrl(image.url) : 'https://cdn-icons-png.flaticon.com/512/4726/4726010.png'}
                alt={`Image ${index}`}
                className={`${classes.img}`}
              />) : (<div><video src={imageUrl(image.url)} className={`${classes.img}`} controls>
                Seu navegador não suporta esse tipo de arquivo!
              </video></div>)}

              <div style={{ textAlign: 'center', padding: '2%' }}>
                <Button
                  variant="outlined"
                  size="small"
                  color="primary"
                  style={{ marginBottom: '5px', width: '100%' }}
                  onClick={() => handleSelectedItem(image)}
                >
                  {image.id == mediaUrl ? 'Remover Item' : 'Selecionar Item'}
                </Button>
                <Button
                  variant="outlined"
                  size="small"
                  color="primary"
                  style={{ width: '100%' }}
                  onClick={() => handleOpenModalEdit(image)}
                >
                  Editar Arquivo
                </Button>
              </div>

            </section>
          </Grid>
        ))}
      </Grid>
    </div>
  );
  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        className={classes.modal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <DialogTitle className={classes.dialogTitle} id="form-dialog-title" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item xs={10}>
              <div>Arquivos da Fila</div>
            </Grid>
            <Grid item xs={2}>
              <Tooltip title="Adicionar Imagem">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setOpenNewUploadModal(true)}
                >
                  <AddCircleOutline />
                </Button>
              </Tooltip>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          {body}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            color="secondary"
            variant="outlined"
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
      <QueueNewUploadModal archive={archive} queueId={queueId} open={openNewUploadModal} getImages={() => getImages()} onClose={() => setOpenNewUploadModal(false)} />
    </div>
  );
};

export default QueueUploadModal;
