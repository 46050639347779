import React, { useEffect, useReducer, useState } from "react";

import openSocket from "../../services/socket-io";

import {
  AddCircleOutline,
  DeleteOutline,
  Edit
} from "@material-ui/icons";

import { 
  parseISO,
  format 
} from "date-fns";

import {
  Button,
  IconButton,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Tooltip
} from "@material-ui/core";

import ConfirmationModal from "../../components/ConfirmationModal";
import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import MotelModal from "../../components/MotelModal";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import Title from "../../components/Title";

import { i18n } from "../../translate/i18n";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import { toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({
  mainPaper: {
    flex: 1,
    padding: theme.spacing(2),
    margin: theme.spacing(1),
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },
  customTableCell: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const Moteis = () => {
  const classes = useStyles();

  const [motels, setMotel] = useState([]);
  const [loading, setLoading] = useState(false);

  const [motelModalOpen, setMotelModalOpen] = useState(false);
  const [selectedMotel, setSelectedMotel] = useState(null);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);

  useEffect(() => {
    (async () => {
      setLoading(true);
      await handleGetMotels()
    })();
  }, []);

  const handleOpenMotelModal = () => {
    setMotelModalOpen(true);
    setSelectedMotel(null);
  };

  const handleCloseMotelModal = () => {
    setMotelModalOpen(false);
    setSelectedMotel(null);
    handleGetMotels()
  };

  const handleEditMotel = (motel) => {
    setSelectedMotel(motel);
    setMotelModalOpen(true);
  };

  const handleCloseConfirmationModal = () => {
    setConfirmModalOpen(false);
    setSelectedMotel(null);
  };
  const handleGetMotels = async () => {
    try {
      const { data } = await api.get("/motel");
      setMotel(data)
    } catch (err) {
      toastError(err);
    } finally {
      setLoading(false);
    }
  };
  const handleDeleteMotel = async (motelId) => {
    try {
      await api.delete(`/motel/${motelId}`);
      toast.success('Motel Deletado com Sucesso!');
    } catch (err) {
      toastError(err);
    }
    
    setSelectedMotel(null);
    handleGetMotels()
  };
  const [open, setOpen] = React.useState(false);

  return (
    <MainContainer>
      <ConfirmationModal
        title={
          selectedMotel &&
          `Deseja deletar o motel ${selectedMotel.name
          }?`
        }
        open={confirmModalOpen}
        onClose={handleCloseConfirmationModal}
        onConfirm={() => handleDeleteMotel(selectedMotel.id)}
      >
        Essa ação não pode ser revertida!
      </ConfirmationModal>
      <MotelModal
        open={motelModalOpen}
        onClose={handleCloseMotelModal}
        motelId={selectedMotel?.id}
      />
      <MainHeader>
        <Title>QR Code ({motels.length})</Title>
        <MainHeaderButtonsWrapper>
          <Tooltip title={i18n.t("motels.buttons.add")}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleOpenMotelModal}
            >
              <AddCircleOutline />
            </Button>
          </Tooltip>
        </MainHeaderButtonsWrapper>
      </MainHeader>
      <Paper className={classes.mainPaper} variant="outlined">
        <Table size="small">
          <TableHead>
            <TableRow>
              {/* <TableCell>
         
              </TableCell> */}
              <TableCell align="center">
                Id
              </TableCell>
              <TableCell align="center">
                Nome
              </TableCell>
              <TableCell align="center">
                Ações
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <>
              {motels.map((motel) => (
                <TableRow key={motel.id}>
                  <TableCell align="center">{motel.id}</TableCell>
                  <TableCell align="center">{motel.name}</TableCell>
                  <TableCell align="center">
                    <IconButton
                      size="small"
                      onClick={() => handleEditMotel(motel)}
                    >
                      <Edit color="secondary" />
                    </IconButton>

                    <IconButton
                      size="small"
                      onClick={() => {
                        setSelectedMotel(motel);
                        setConfirmModalOpen(true);
                      }}
                    >
                      <DeleteOutline color="secondary" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
              {loading && <TableRowSkeleton columns={4} />}
            </>
          </TableBody>
        </Table>
      </Paper>
    </MainContainer>
  );
};

export default Moteis;
